import React, { useState, useEffect, StrictMode } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import { API_BASE_URL } from '../../constant/apiconstant';

const SurveyCategory = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const accountId = localStorage.getItem("accountId");
  console.log(accountId);
    
    const fetchData = async () => {
      try {
        
        const token = localStorage.getItem("jwt"); // Get the access token from localStorage

        const response = await axios.get(
          `${API_BASE_URL}category`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Set the Authorization header with the access token
            },
          }
        ); // Replace this URL with your API endpoint
        const filteredData = response.data.filter(item => item.accountId === accountId);
        setData(filteredData);
       
        // Update the state with the retrieved data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    useEffect(() => {
    fetchData(); // Call the function to fetch data when the component mounts
  }, []);

  

  const handleDelete = async (_id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Category?"
    );
    if (!confirmDelete) {
      return; // If the user clicks Cancel, do nothing
    }
    try {
      // Send a DELETE request to the API using axios

      const token = localStorage.getItem("jwt"); // Get the access token from localStorage
      await axios.delete(`${API_BASE_URL}category/delete/${_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with the access token
        },
      });
      // Once the account is deleted, you can fetch the updated data again or remove the deleted row from the local state if you have pagination.
      // For simplicity, we will refetch the data here.
      setData((prevData) => prevData.filter((item) => item._id !== _id));
      fetchData();
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  const columns = [
    {
      name: "S.No",
      cell: (a, i) => i + 1,
      sortable: false,
    },
    {
      name: "Category Name",
      selector: (row) => row.categoryName,
      sortable: true,
    },
    {
      name: "Agency Name",
      selector: (row) => row.accountName,
      sortable: true,
    },
   
    {
      name: 'Status',
      selector: (row) => row.isActive,
      sortable: true,
      cell: (row) => (
        <button
          className={`btn btn-sm ${row.isActive ? 'btn-outline-success' : 'btn-outline-danger'}`}
        >
          {row.isActive ? 'Active' : 'Inactive'}
        </button>
      ),
    },
    {
      name: "Created Date",
      selector: (row) => new Date(row.createDate).toLocaleDateString("en-IN"),
      sortable: true,
    },
    // {
    //   name: "Pre Prices",
    //   selector: (row) => row.prePrice,
    //   sortable: true,
    // },
    // {
    //   name: "Post Prices",
    //   selector: (row) => row.postPrice,
    //   sortable: true,
    // },
    // {
    //   name: "Complete Survey Price",
    //   selector: (row) => row.completeSurveyPrice,
    //   sortable: true,
    // },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Link
            to={`/edit-category?_id=${row._id}`}
            className="btn btn-outline-primary btn-sm waves-effect waves-light"
            title="Edit Agency"
          >
            <i className="fas fa-edit"></i>
          </Link>{" "}
          <Link
            to="#"
            className="btn btn-outline-primary btn-sm waves-effect waves-light"
            title="Delete Agency"
            onClick={() => handleDelete(row._id)}
          >
            <i className="fa fa-trash"></i>
          </Link>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  // Custom filter function for search
  const customFilter = (rows, searchTerm) => {
    return rows.filter(
      (row) =>
        row.categoryName.toLowerCase().includes(searchTerm.toLowerCase()) 
      // Add more properties if you want to search in additional columns
    );
  };

  // Custom styles for the search box
  const searchBoxStyles = {
    container: {
      textAlign: "right",
      marginBottom: "10px", // Optional, add some bottom margin
    },
    input: {
      borderRadius: "4px",
      padding: "5px 10px",
      border: "1px solid #ccc",
      boxShadow: "none",
      float: "left", // Set the desired width of the search box
      marginBottom: "10px", // Optional, add some right margin to the search box
      marginTop: "10px",
    },
  };

  return (
    <div>
      <div id="layout-wrapper">
        
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Survey Category</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Survey Category</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                     
                      <div className="row">
                        <div className="col-lg-4">
                        <input
                          type="text"
                          placeholder="Search..."
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          style={searchBoxStyles.input}
                        />
                        </div>
                        <div className="col-lg-6"></div>
                        <div className="col-lg-2">
                          <Link
                            to="/add-category"
                            className="btn btn-outline-primary btn-sm waves-effect waves-light addusers">
                            <i className="fa fa-plus"></i> Add Survey Category
                          </Link>
                        </div>
                      </div>
                      <div className="table-responsive">
                        
                        <DataTable
                          columns={columns}
                          data={
                            searchText.trim() !== ""
                              ? customFilter(data, searchText)
                              : data
                          }
                          pagination
                          searchable
                        />
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightbar-overlay"></div>
    </div>
  );
};

export default SurveyCategory;
