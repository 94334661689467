import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AccountTotalData from "./AccountTotalData";
import UserCounts from "./UserCounts";
import SurveyCount from "./SurveyCount";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import { API_BASE_URL } from '../constant/apiconstant';

const AdminList = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("jwt");

  if (!token) {
    localStorage.clear();
    navigate("/login");
  }

  const decodedToken = jwtDecode(token);
  const accountId = decodedToken.accountId ? decodedToken.accountId : null;
  const username = decodedToken.username;
  const role = decodedToken.role;
  localStorage.setItem("accountId", accountId);
  localStorage.setItem("username", username);
  localStorage.setItem("role", role);

  const [surveyData, setSurveyData] = useState([]);
  const [prePrice, setPriPrice] = useState("");
  const [postPrice, setPostPrice] = useState("");
  const [completePrice, setCompletePrice] = useState("");
  const [loadingSurvey, setLoadingSurvey] = useState(true);
  const [loadingAccount, setLoadingAccount] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isRoleSuperAdmin, setIsRoleSuperAdmin] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [data, setData] = useState([]);
  useEffect(() => {
    setLoadingSurvey(true);
    axios
      .get(
        `${API_BASE_URL}survey/getAlldata?limit=1000&skip=0&preSurvey=false`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const token = localStorage.getItem("jwt");
        const decodedToken = jwtDecode(token);
        const loggedInUserId = decodedToken.accountId;
        setSurveyData(response.data.data);
        const allData = response.data.data;
        setLoading(false);
        const userRole = decodedToken.role;
        setIsRoleSuperAdmin(userRole === "superadmin");

        if (loggedInUserId) {
          const filteredData = allData.filter(
            (item) => item.accountId === loggedInUserId
          );
          setSurveyData(filteredData);
        } else {
          setSurveyData(allData);
        }
        setLoadingSurvey(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoadingSurvey(false);
      });

    setLoadingAccount(true);
    axios
      .get(`${API_BASE_URL}account/getAlldata?limit=1000&skip=0`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const accountData = response.data;
        if (accountId) {
          const account = accountData.find((item) => item._id === accountId);
          if (account) {
            setPriPrice(account.prePrice);
            setPostPrice(account.postPrice);
            setCompletePrice(account.completeSurveyPrice);
          }
        }
        setLoadingAccount(false);
      })
      .catch((error) => {
        console.error("Error fetching account data:", error);
        setLoadingAccount(false);
      });
  }, [accountId, token]);

  useEffect(() => {
    const accountId = localStorage.getItem("accountId");
    if (accountId !== "null") {
      const fetchAgencyData = async () => {
        try {
          const response = await fetch(
            `${API_BASE_URL}account/${accountId}`
          );
          const jsonData = await response.json();
          setPriPrice(jsonData.prePrice);
          setPostPrice(jsonData.postPrice);
          setCompletePrice(jsonData.completeSurveyPrice);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchAgencyData();
    }
  }, [accountId]);

  console.log(localStorage.getItem("prePrice"));
  console.log(localStorage.getItem("postPrice"));
  console.log(localStorage.getItem("completeSurveyPrice"));

  let totalSurveyPrice = 0;

  const columns = [
    {
      name: "S No.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Registration No.",
      selector: "registrationNo",
      sortable: true,
    },
    {
      name: "Email Id",
      selector: "emailId",
      sortable: true,
    },
    {
      name: "Agency Name",
      selector: "accountName",
      sortable: true,
    },
    {
      name: "Survey Status",
      cell: (row) => {
        const isPreSurveyComplete = row.preSurvey.length > 0;
        const isPostSurveyComplete = row.postSurvey.length > 0;
        let surveyStatus = '';
        let buttonClass = '';
        if (isPreSurveyComplete && isPostSurveyComplete) {
          surveyStatus = 'Complete Survey';
          buttonClass = 'btn-outline-success';
        } else if (isPreSurveyComplete) {
          buttonClass = 'btn-outline-warning';
          surveyStatus = 'Pre-Survey';
        }
        return (
          <span className={`btn btn-sm ${buttonClass}`}>
           {surveyStatus}
         </span>
        );
      },
      width: "150px",
    },
    {
      name: "Survey Price",
      selector: (row) => {
        let surveyPriceSet = "";
        if (row.preSurvey.length > 0 && row.postSurvey.length > 0) {
          surveyPriceSet = completePrice;
        } else if (row.preSurvey.length > 0) {
          surveyPriceSet = prePrice;
        } else if (row.postSurvey.length > 0) {
          surveyPriceSet = postPrice;
        }
        return surveyPriceSet;
      },
      sortable: true,
    },
    {
      name: "Survey Report",
      cell: (row) => (
        <a
          href={`/reports?surveyId=${row._id}`}
          className="btn btn-outline-primary btn-sm waves-effect waves-light"
          title="View Reports"
          target="_blank"
        >
          <i className="fas fa-poll"></i>
        </a>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const customFilter = (rows, searchTerm) => {
    return rows.filter(
      (row) =>
        row.registrationNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.emailId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.accountName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.surveyType.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
  return (
    <div>
      <div id="layout-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Dashboard</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Dashboard</Link>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {role === "superadmin" && <AccountTotalData />}
            <SurveyCount />
            <UserCounts />
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Recent Survey List</h4>
                  <div className="table-responsive">
                  <DataTable
                  columns={columns}
                  data={
                    searchText.trim() !== ""
                      ? customFilter(surveyData, searchText)
                      : surveyData
                  }
                  pagination
                  searchable
                />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <div className="rightbar-overlay"></div>
    </div>
  );
};

export default AdminList;
