import React, { useState, useEffect, StrictMode } from "react";
import { Link } from "react-router-dom";
import Header from "../../Pages/Header";
import SideBarManu from "../../Pages/SideBarMenu";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Helmet } from 'react-helmet';
import { API_BASE_URL } from '../../constant/apiconstant';

const Agencies = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const token = localStorage.getItem("jwt");
  //console.log("acccestoken", token);
  const bearerToken = token; 
  const config = {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Type": "application/json", // Assuming your API accepts JSON data
    },
  };
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}account/getAlldata?limit=100&skip=0`,config); // Replace this URL with your API endpoint
        setData(response.data);
        //console.log("data", response.data);

        // Update the state with the retrieved data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData(); // Call the function to fetch data when the component mounts
  }, []);


  const staticImagePath = `${API_BASE_URL}image/`;
  const handleDelete = async (_id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this agency?");
  if (!confirmDelete) {
    return; // If the user clicks Cancel, do nothing
  }
    try {
      // Send a DELETE request to the API using axios
      await axios.delete(`${API_BASE_URL}account/delete/${_id}`);
      // Once the account is deleted, you can fetch the updated data again or remove the deleted row from the local state if you have pagination.
      // For simplicity, we will refetch the data here.
      setData(prevData => prevData.filter(item => item._id !== _id));
    
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  const updateData = (_id, updatedData) => {
    setData(prevData => {
      const updatedDataList = prevData.map(item => {
        if (item._id === _id) {
          return {
            ...item,
            ...updatedData,
          };
        }
        return item;
      });
      return updatedDataList;
    });
  };

  
  const columns = [
    {
      name: "S.No",
      cell: (a, i) => i + 1,
      sortable: false,
      width:"70px"
       
    },
    {
      name: "Agency Name",
      selector: (row) => row.accountName,
      sortable: true,
      width:"150px"
    },
    {
      name: 'Logo',
      selector: row => row.logo,
      sortable: true,
      cell: row => (
        // Check if the 'logo' property exists in the row
        row.logo ? (
          <img src={staticImagePath + row.logo} alt="Logo" style={{ width: '100%', height: '100%',border:'1px solid #ccc',padding: '5px'}}/>
        ) : (
          <span>No Logo</span>
        )
      ),
      
    },
    {
      name: "Address",
      selector: (row) => (
        <div>
          {row.address.split('\n').map((line, index) => (
            <div key={index}>{line}</div>
          ))}
        </div>
      ),
      sortable: true,
      width:"200px"
    },
    {
      name: "Complete Survey Price",
      selector: (row) => row.completeSurveyPrice || "N/A",
      sortable: true,
      width:"100px"
    },
    
    {
      name: "Is Active",
      selector: (row) => row.isActive,
      sortable: true,
      
      cell: (row) => (<button
        className={`btn btn-sm ${row.isActive ? 'btn-outline-success' : 'btn-outline-danger'}`}
      >
        {row.isActive ? 'Active' : 'Inactive'}
      </button>),
    },
    {
      name: "Created Date",
      selector: (row) => new Date(row.createDate).toLocaleDateString("en-IN"),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Link
            to={`/edit-agency?_id=${row._id}`}
            className="btn btn-outline-primary btn-sm waves-effect waves-light"
            title="Edit Agency"
          >
            <i className="fas fa-edit"></i>
          </Link>{" "}
          <Link
            to="#"
            className="btn btn-outline-primary btn-sm waves-effect waves-light"
            title="Delete Agency"
            onClick={() => handleDelete(row._id)}
          >
            <i className="fa fa-trash"></i>
          </Link>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  // Custom filter function for search
  const customFilter = (rows, searchTerm) => {
    return rows.filter(
      (row) =>
        row.accountName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.address.toLowerCase().includes(searchTerm.toLowerCase())
      // Add more properties if you want to search in additional columns
    );
  };

  // Custom styles for the search box
  const searchBoxStyles = {
    container: {
      textAlign: "right",
      marginBottom: "10px", // Optional, add some bottom margin
    },
    input: {
      borderRadius: "4px",
      padding: "5px 10px",
      border: "1px solid #ccc",
      boxShadow: "none",
      float: "left", // Set the desired width of the search box
      marginBottom: "10px", // Optional, add some right margin to the search box
      marginTop: "10px",
    },
  };



  return (
    <div>
      <Helmet>
        <title>Agency List | Pre-Post Survey</title>
      </Helmet> 
      <div id="layout-wrapper">
       
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Agency</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to ="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Agency</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                     
                      <div className="row">
                        <div className="col-lg-4">
                        <input
                          type="text"
                          placeholder="Search..."
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          style={searchBoxStyles.input}
                        />
                        </div>
                        <div className="col-lg-6"></div>
                        <div className="col-lg-2">
                          <Link
                            to="/add-agency"
                            className="btn btn-outline-primary btn-sm waves-effect waves-light addusers">
                            <i className="fa fa-plus"></i> Add Agency
                          </Link>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={
                            searchText.trim() !== ""
                              ? customFilter(data, searchText)
                              : data
                          }
                          pagination
                          searchable
                          style={{ padding: '0' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightbar-overlay"></div>
    </div>
  );
};

export default Agencies;
