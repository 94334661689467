import React from 'react'

const Errorbox = () => {
  return (
  
    <div style={{ backgroundColor: 'red', color: 'white', padding: '10px', marginBottom: '10px', alignContent:'center', borderRadius: '5px'}}>
    {" Enter Valid Username and Password"}
  </div>
 
  )
}

export default Errorbox
