import React, { useState, useEffect, StrictMode } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import RadioButton from "./RadioButton";
import { API_BASE_URL } from '../../constant/apiconstant';

const AddQuestion = () => {
  // Existing state variables for the "Add User" form
  // ... (the existing state variables from the previous code)

  // State variables for the "Create Question" form
  const [categoryId, setCategoryId] = useState({});
  const [questionType, setQuestionType] = useState("");
  const [question, setQuestion] = useState("");
  const [refAnswerID, setRefAnswerID] = useState("");
  const [ordinalPosition, setOrdinalPosition] = useState(0);
  const [answer, setAnswer] = useState([{ answerID: "string", answer: "" }]);
  const [imagetag, setImagetag] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [modifiedBy, setModifiedBy] = useState("");
  const [categories, setCategories] = useState([]);
  const [is_capture_image, setis_capture_image] = useState(true); // Initialize with a default value
  const [isCategorySelected, setIsCategorySelected] = useState(false);

  const [isOptionSelected, setIsOptionSelected] = useState(false);

  const [questionError, setQuestionError] = useState(""); // Add question error state

  const [alert, setAlert] = useState({ message: "", color: "" });

  const [options, setOptions] = useState([{ optionID: "string", option: "" }]);
  const [dropdownOptions, setDropdownOptions] = useState([
    { optionID: "string", option: "" },
  ]);
  // Add a state variable for the number of checkbox options
  const [checkboxOptions, setCheckboxOptions] = useState([
    { answerID: "string", answer: "" },
  ]);
  // Function to add a new checkbox option
  const addNewCheckboxOption = () => {
    // Limit the number of options to a certain maximum value (e.g., 22)
    if (checkboxOptions.length < 22) {
      setCheckboxOptions((prevOptions) => [
        ...prevOptions,
        { answerID: "string", answer: "string" },
      ]);
    }
  };
  const handleCheckboxOptionChange = (index, field, value) => {
    setCheckboxOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index][field] = value;
      return updatedOptions;
    });
  };
  // Function to remove a checkbox option
  const removeCheckboxOption = (index) => {
    setCheckboxOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions.splice(index, 1);
      return updatedOptions;
    });
  };
  const addNewDropdownOption = () => {
    // Limit the number of options to a certain maximum value (e.g., 22)
    if (dropdownOptions.length < 10) {
      setDropdownOptions((prevOptions) => [
        ...prevOptions,
        { optionID: "string", option: "" },
      ]);
    }
  };
  const addNewOption = () => {
    // Limit the number of options to a certain maximum value (e.g., 5)
    if (options.length < 2) {
      setOptions((prevOptions) => [
        ...prevOptions,
        { optionID: "string", option: "" },
      ]);
    }
  };

  // Function to handle changes in a dropdown option
  const handleDropdownOptionChange = (index, field, value) => {
    setDropdownOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index][field] = value;
      return updatedOptions;
    });
  };

  // Function to remove a dropdown option
  const removeDropdownOption = (index) => {
    setDropdownOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions.splice(index, 1);
      return updatedOptions;
    });
  };

  const addNewAnswerField = () => {
    setAnswer((prevAnswer) => [
      ...prevAnswer,
      { answerID: "string", answer: "string" },
    ]);
  };

  // Handler to update the answer fields
  const handleAnswerChange = (index, field, value) => {
    setAnswer((prevAnswer) => {
      const updatedAnswer = [...prevAnswer];
      updatedAnswer[index][field] = value;
      return updatedAnswer;
    });
  };

  const token = localStorage.getItem("jwt");
  const accountId = localStorage.getItem("accountId");
  //console.log("acccestoken", token);
  const bearerToken = token;
  // Handler to submit the "Create Question" form

  const navigate = useNavigate();

  const handleQuestionSubmit = async (e) => {
    e.preventDefault();

    if (!isCategorySelected) {
      // Display an error message or take appropriate action
      console.error("Please select a category.");
      setAlert({ message: "Fill the Category.", color: "danger" });
      return;
    }

    if (!isOptionSelected) {
      // Check if an option is selected
      console.error("Please select a question type.");

      setIsOptionSelected(true); // Set it to true to indicate an error
      setAlert({ message: "Select Question Type.", color: "danger" });
      return;
    } else {
      setIsOptionSelected(false); // Reset it to false when an option is selected
    }
    if (!question.trim()) {
      // Display an error message for the question error
      setQuestionError("Please enter a question.");
      return;
    }

    // Clear any previous error messages
    setAlert({ message: "", color: "" });
    setQuestionError("");

    const formData = {
      accountId,
      categoryId,
      questionType,
      question,
      refAnswerID,
      ordinalPosition,
      answer,
      imagetag,
      createdBy,
      isActive,
      modifiedBy,
      is_capture_image,
    };
    // Depending on the question type, modify the formData
    switch (questionType) {
      case "Radio":
        formData.answer = options.map((opt) => ({ answer: opt.option }));
        break;
      case "Checkbox":
        formData.answer = checkboxOptions.map((ans) => ({
          answer: ans.answer,
        }));
        break;
      case "TextBox":
      case "TextArea":
      case "Dropdown":
        formData.answer = dropdownOptions.map((opt) => ({
          answer: opt.option,
        }));
        // formData.answer = answer.map((ans) => ({ answer: ans.answer }));
        break;
      // Add more cases for other question types if needed
      default:
        break;
    }
    console.warn("formData before POST:", formData);

    try {
      const response = await axios.post(
        `${API_BASE_URL}question/create`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      console.log("Question data submitted successfully!");
      console.warn("Response data:", response.data);
      console.warn("Selected Category ID:", categoryId);

      navigate("/question");
    } catch (error) {
      console.error("Error submitting question data:", error);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}category`,
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          }
        );
        const filteredData = response.data.filter(
          (item) => item.accountId === accountId
        );
        setCategories(filteredData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleOptionChange = (index, field, value) => {
    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index][field] = value;
      return updatedOptions;
    });
  };

  const removeOption = (index) => {
    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions.splice(index, 1);
      return updatedOptions;
    });
  };

  return (
    <div>
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid"></div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <h4 className="card-title mb-4">Questions</h4>
                      </div>
                      <div className="col-lg-6 text-right">
                        <Link
                          to="/question"
                          className="btn btn-outline-primary btn-sm waves-effect waves-light"
                          style={{ float: "right" }}
                        >
                          <i className="fa fa-question-circle"></i> Questions
                        </Link>
                      </div>
                    </div>

                    <form
                      className="form-horizontal"
                      onSubmit={handleQuestionSubmit}
                    >
                      <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                          <div className="col-md-12">
                            {/* Add other fields related to the "Create Question" form */}

                            {alert.message && (
                              <div
                                className={`alert alert-${alert.color} d-flex justify-content-between align-items-center`}
                              >
                                <span>{alert.message}</span>
                                <button
                                  className="btn-close btn-close-white"
                                  onClick={() =>
                                    setAlert({ message: "", color: "" })
                                  }
                                />
                              </div>
                            )}
                            <div className="mb-3">
                              <label
                                htmlFor="categoryId"
                                className="form-label"
                              >
                                Category
                              </label>
                              <select
                                className="form-control"
                                value={categoryId}
                                onChange={(e) => {
                                  setCategoryId(e.target.value);
                                  setIsCategorySelected(e.target.value !== "");
                                }}
                              >
                                <option value="">Select a category</option>
                                {categories.map((category) => (
                                  <option
                                    key={category._id}
                                    value={category._id}
                                  >
                                    {category.categoryName}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="mb-3">
                              <label className="form-label">
                                Is Capture Image?.
                              </label>
                              <br />
                              {"  "}
                              <div className="radio-button-group">
                                <RadioButton
                                  className="form-control"
                                  label={
                                    <span className="radio-label">Yes</span>
                                  }
                                  value={true}
                                  checked={is_capture_image === true}
                                  onChange={() => setis_capture_image(true)}
                                />
                                <RadioButton
                                  className="form-control"
                                  label={
                                    <span className="radio-label">No</span>
                                  }
                                  value={false}
                                  checked={is_capture_image === false}
                                  onChange={() => setis_capture_image(false)}
                                />
                              </div>
                            </div>
                            {is_capture_image === true && (
                              <div className="mb-3">
                                <label
                                  htmlFor="imagetag"
                                  className="form-label"
                                >
                                  Image Tag
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="imagetag"
                                  value={imagetag}
                                  onChange={(e) => setImagetag(e.target.value)}
                                />
                              </div>
                            )}
                            <div className="mb-3">
                              <label
                                htmlFor="questionType"
                                className="form-label"
                              >
                                Question Type
                              </label>
                              <select
                                className="form-control"
                                value={questionType}
                                onChange={(e) => {
                                  setQuestionType(e.target.value);
                                  setIsOptionSelected(e.target.value !== ""); // Update isOptionSelected
                                }}
                              >
                                <option value="">Select question type</option>
                                <option value="Radio">Radio</option>
                                <option value="Dropdown">Dropdown</option>
                                <option value="TextBox">TextBox</option>
                                <option value="TextArea">TextArea</option>
                                <option value="Checkbox">Checkbox</option>
                                {/* Add other options as needed */}
                              </select>
                            </div>
                            {questionType === "Radio" && (
                              <div className="mb-3">
                                <label htmlFor="options" className="form-label">
                                  Options
                                </label>
                                {options.map((option, index) => (
                                  <div key={index} className="input-group mb-2">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={`Option ${index + 1}`}
                                      value={option.option}
                                      onChange={(e) =>
                                        handleOptionChange(
                                          index,
                                          "option",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() => removeOption(index)}
                                    >
                                     <i class="fa fa-times" aria-hidden="true"></i>
                                    </button>
                                  </div>
                                ))}
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={addNewOption}
                                  disabled={options.length >= 2}
                                >
                                 <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                              </div>
                            )}
                            {questionType === "Dropdown" && (
                              <div className="mb-3">
                                <label
                                  htmlFor="dropdownOptions"
                                  className="form-label"
                                >
                                  Dropdown Options
                                </label>
                                {dropdownOptions.map((option, index) => (
                                  <div key={index} className="input-group mb-2">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={`Option ${index + 1}`}
                                      value={option.option}
                                      onChange={(e) =>
                                        handleDropdownOptionChange(
                                          index,
                                          "option",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() =>
                                        removeDropdownOption(index)
                                      }
                                    >
                                      <i class="fa fa-times" aria-hidden="true"></i>
                                    </button>
                                  </div>
                                ))}
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={addNewDropdownOption}
                                  disabled={dropdownOptions.length >= 10}
                                >
                                   <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                              </div>
                            )}

                            {questionType === "Checkbox" && (
                              <div className="mb-3">
                                <label
                                  htmlFor="checkboxOptions"
                                  className="form-label"
                                >
                                  Checkbox Options
                                </label>
                                {checkboxOptions.map((option, index) => (
                                  <div key={index} className="input-group mb-2">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={`Option ${index + 1}`}
                                      value={option.answer}
                                      onChange={(e) =>
                                        handleCheckboxOptionChange(
                                          index,
                                          "answer",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() =>
                                        removeCheckboxOption(index)
                                      }
                                    >
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                    </button>
                                  </div>
                                ))}
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={addNewCheckboxOption}
                                  disabled={checkboxOptions.length >= 22}
                                >
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                              </div>
                            )}
                            <div className="mb-3">
                              <label htmlFor="question" className="form-label">
                                Question
                              </label>
                              <textarea
                                className={`form-control ${
                                  questionError ? "is-invalid" : ""
                                }`}
                                name="question"
                                onChange={(e) => {
                                  setQuestion(e.target.value);
                                  setQuestionError(""); // Clear the error when the user starts typing
                                }}
                                value={question}
                                required
                              ></textarea>
                              {questionError && (
                                <div className="invalid-feedback">
                                  {questionError}
                                </div>
                              )}
                            </div>

                            <div className="mb-3">
                              <label htmlFor="isActive" className="form-label">
                                Is Active
                              </label>
                              <select
                                className="form-control"
                                value={isActive}
                                onChange={(e) =>
                                  setIsActive(e.target.value === "true")
                                }
                              >
                                <option value={true}>Active</option>
                                <option value={false}>Inactive</option>
                              </select>
                            </div>

                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light btn-rounded px-5"
                              onClick={handleQuestionSubmit}
                            >
                              Submit Question
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightbar-overlay"></div>
    </div>
  );
};
export default AddQuestion;
