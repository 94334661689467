import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from '../constant/apiconstant';

const Header = () => {
  const [isNotificationVisible, setNotificationVisible] = useState(false);
  const [isProfileVisible, setProfileVisible] = useState(false);
  const [role, setRole] = useState(localStorage.getItem("role"));

  const handleToggle = (divId) => {
    if (divId === "notification_id") {
      setNotificationVisible(!isNotificationVisible);
      setProfileVisible(false);
    } else if (divId === "profile_id") {
      setProfileVisible(!isProfileVisible);
      setNotificationVisible(false);
    }
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    // Remove the access token from local storage
    localStorage.removeItem("jwt");
    localStorage.clear()
    window.sessionStorage.clear();

    navigate("/login");
    window.location.reload();
    window.history.replaceState(null, "/login", "/login");

  };
  



  const [data, setData] = useState([]);
  const token = localStorage.getItem("jwt");
  //console.log("acccestoken", token);
  const bearerToken = token;

  useEffect(() => {

    axios.get(`${API_BASE_URL}user/profile`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    })
      .then(response => {
        //console.log(response.data); 
        setData(response.data);
        setRole(localStorage.getItem("role"));
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);



  const [apiData, setApiData] = useState(null);
  useEffect(() => {
    const accountId = localStorage.getItem("accountId");
    const role = localStorage.getItem("role");

    const fetchData = async () => {
      if (accountId && role !== 'superadmin') {
        try {
          const response = await fetch(`${API_BASE_URL}account/${accountId}`);
          const data = await response.json();
          setApiData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, []);

  //console.log(apiData);

  const baseImageUrl = `${API_BASE_URL}/image/`;

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);

    // Check if the classes are already on the body element
    const bodyElement = document.body;
    const hasSidebarClass = bodyElement.classList.contains('sidebar-enable');
    const hasCollapsedClass = bodyElement.classList.contains('vertical-collpsed');

    // Toggle the classes on the body element
    if (!hasSidebarClass) {
      bodyElement.classList.add('sidebar-enable');
    } else {
      bodyElement.classList.remove('sidebar-enable');
    }

    if (!hasCollapsedClass) {
      bodyElement.classList.add('vertical-collpsed');
    } else {
      bodyElement.classList.remove('vertical-collpsed');
    }
  };

  return (
    <div>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo">
                <span className="logo-sm">
                  {localStorage.getItem("role") != 'superadmin' && apiData ? (
                    <div>
                      {apiData.logo ? (
                        <img src={`${baseImageUrl}${apiData.logo}`} alt={apiData.accountName} />
                      ) : (
                        <img
                          src="assets/images/logo-1.png"
                          alt="Pre Post Survey"
                          title="Pre Post Survey"
                        />
                      )}
                    </div>
                  ) : (
                    <img
                      src="assets/images/logo-1.png"
                      title="Pre Post Survey"
                    />
                  )}
                </span>
                <span className="logo-lg">
                  {localStorage.getItem("role") != 'superadmin' && apiData ? (
                    <div>
                      {apiData.logo ? (
                        <img src={`${baseImageUrl}${apiData.logo}`} alt={apiData.accountName} />
                      ) : (
                        <img
                          src="assets/images/logo-1.png"
                          alt="Pre Post Survey"
                          title="Pre Post Survey"
                        />
                      )}
                    </div>
                  ) : (
                    <img
                      src="assets/images/logo-1.png"
                      title="Pre Post Survey"
                    />
                  )}
                </span>
              </Link>
            </div>
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              onClick={toggleSidebar}
              id="vertical-menu-btn"
            >
              <i className={`fa fa-fw ${sidebarOpen ? 'fa-times' : 'fa-bars'}`}></i>
            </button>
          </div>
          <div className="d-flex">

            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => handleToggle("profile_id")}
              >
                <img
                  className="rounded-circle header-profile-user"
                  src="assets/images/users/avatar.png"
                  alt="Header Avatar"
                />{" "}
                <span className="d-none d-xl-inline-block ms-1" key="t-henry">
                  {localStorage.getItem("role") != 'superadmin' && apiData ? (
                    <div>
                      {apiData.logo ? (
                        <>{apiData.accountName} ({data.username})</>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <>{data.username}</>
                  )}

                </span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
              </button>
              {isProfileVisible && (
                <div
                  className="dropdown-menu dropdown-menu-end show"
                  id="profile_id"
                  style={{
                    position: "absolute",
                    inset: "0px 0px auto auto",
                    margin: "0px",
                    transform: "translate(0px, 72px)",
                  }}
                >
                  {role === 'Admin' && (
                    <>
                      <Link
                        to="/profile"
                        className="dropdown-item"
                      >
                        <i className="bx bx-user font-size-16 align-middle me-1"></i>{" "}
                        <span key="t-profile">Agency Profile</span>
                      </Link>
                      <div class="dropdown-divider"></div>
                      <Link
                        to="/setting"
                        className="dropdown-item"
                      >
                        <i className="bx bx-wrench font-size-16 align-middle me-1"></i>{" "}
                        <span key="t-settings">Setting</span>
                      </Link>
                      <div class="dropdown-divider"></div>
                    </>
                  )}
                  <Link
                    to="/login"
                    className="dropdown-item text-danger"
                    onClick={handleLogout}
                  >
                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
                    <span key="t-logout">Logout</span>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
