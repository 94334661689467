import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Errorbox from "../Component/Errorbox";
import { Helmet } from "react-helmet";
import html2pdf from "html2pdf.js";
import { FaPlus, FaMinus } from "react-icons/fa";
import { API_BASE_URL } from '../constant/apiconstant';



const UnpaidReport = () => {
  const [surveyData, setSurveyData] = useState(null);
  const [surveyStatus, setSurveyStatus] = useState("");
  const [surveyDate, setSurveyDate] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const surveyId = queryParams.get("surveyId");

  const [preImageUrls, setPreImageUrls] = useState([]);
  const [postImageUrls, setPostImageUrls] = useState([]);
  const [logoImageUrl, setLogoImageUrl] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [accountData, setAccountData] = useState(null);

  useEffect(() => {
    const fetchSurveyData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}survey/${surveyId}`
        );
        setSurveyData(response.data);
        if (response.data.dataId && response.data.dataId.accountId) {
          setAccountId(response.data.dataId.accountId);
        }
        if (response.data.dataId.postSurvey.length == 0) {
          setSurveyDate(response.data.dataId.preSurveyCreatedDate);
          setSurveyStatus("Pre Survey");
        } else if (
          response.data.dataId.preSurvey.length > 0 &&
          response.data.dataId.postSurvey.length > 0
        ) {
          setSurveyDate(response.data.dataId.postSurveyCreatedDate);
          setSurveyStatus("Complete Survey");
        }

        //console.log(surveyStatus);
      } catch (error) {
        console.error("Error fetching survey data:", error);
      }
    };

    fetchSurveyData();
  }, [surveyId]);

  const fetchAccountData = async () => {
    try {
      const accountResponse = await fetch(
        `${API_BASE_URL}account/${accountId}`
      );
      const accountData = await accountResponse.json();
      if (Object.keys(accountData).length > 0) {
        setAccountData(accountData);
      } else {
        console.warn("Fetched account data is empty.");
      }
    } catch (error) {
      console.error("Error fetching account data:", error);
    }
  };

  useEffect(() => {
    if (accountId) {
      fetchAccountData();
    }
  }, [accountId]);

  const baseImageUrl = `${API_BASE_URL}survey-image/`;

  const convertToPdf = () => {
    const element = document.getElementById("content-to-convert"); // Replace with the ID of the HTML element you want to convert

    const opt = {
      margin: 10,
      filename: `${surveyId}_survey.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().from(element).set(opt).save();
  };

  const getBase64Image = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (accountData && accountData.logo) {
      const logoImageUrl = `${probaseImageUrl}${accountData.logo}`;
      getBase64Image(logoImageUrl).then((base64Logo) => {
        if (base64Logo) {
          setLogoImageUrl(base64Logo);
        }
      });
    }
  }, [accountData]);

  useEffect(() => {
    const fetchImages = async () => {
      if (surveyData && surveyData.dataId && surveyData.dataId.preSurvey) {
        const preImages = await Promise.all(
          surveyData.dataId.preSurvey.map(async (preQuestion) => {
            if (preQuestion.imagepath) {
              return `${baseImageUrl}${preQuestion.imagepath}`;
            } else {
              return null;
            }
          })
        );
        setPreImageUrls(preImages);
      }

      if (surveyData && surveyData.dataId && surveyData.dataId.postSurvey) {
        const postImages = await Promise.all(
          surveyData.dataId.postSurvey.map(async (postQuestion) => {
            if (postQuestion.imagepath) {
              return `${baseImageUrl}${postQuestion.imagepath}`;
            } else {
              return null;
            }
          })
        );
        setPostImageUrls(postImages);
      }
    };

    if (surveyData) {
      fetchImages();
    }
  }, [baseImageUrl, surveyData]);

  const probaseImageUrl = `${API_BASE_URL}image/`;

  const [openSection, setOpenSection] = useState(1); // Default to the first section being open

  const toggleSection = (index) => {
    if (openSection === index) {
      // If the clicked section is already open, close it
      setOpenSection(null);
    } else {
      // Otherwise, open the clicked section
      setOpenSection(index);
    }
  };

  return (
    <>
      <Helmet>
        <title>Unpaid Report | Pre-Post Survey</title>
      </Helmet>
      <div id="layout-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="wrap">
                    <section
                      className={`border-1 one ${
                        openSection === 1 ||
                        (openSection === null && openSection === 1)
                          ? "opendiv"
                          : "closediv"
                      }`}
                    >
                      <div>
                        {surveyData && (
                          <>
                            <div className="row">
                              <div className="col-md-12 mb-3">
                              <h4
                              className="photo-heading d-flex align-items-center"
                              onClick={() => toggleSection(1)}
                            >
                              <span>
                                {openSection === 1 ? (
                                  <FaMinus />
                                ) : (
                                  <FaPlus />
                                )}
                              </span>{" "}
                              Pre Survey Date:{" "}
                              {new Date(
                                new Date(surveyData.dataId.preSurveyCreatedDate).getTime() - 6 * 60 * 60 * 1000 + 30 * 60 * 1000
                              ).toLocaleString("en-IN", {
                                timeZone: "Asia/Kolkata",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                second: "numeric",
                              })}
                            </h4>
                            
                              </div>
                              <div className="col-md-4 col-sm-6 mb-3 hd-none">
                                <div className="reg-contant">
                                  <p>Registration No.</p>
                                  <span>
                                    {surveyData.dataId.registrationNo}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 mb-3 hd-none">
                                <div className="reg-contant">
                                  <p>Make</p>
                                  <span></span>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 mb-3 hd-none">
                                <div className="reg-contant">
                                  <p>Modal No.</p>
                                  <span></span>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 mb-3 hd-none">
                                <div className="reg-contant">
                                  <p>Customer Name </p>
                                  <span>{surveyData.dataId.customername}</span>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 mb-3 hd-none">
                                <div className="reg-contant">
                                  <p>Mobile No.</p>
                                  <span>
                                    {surveyData.dataId.customermobile}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 mb-3 hd-none">
                                <div className="reg-contant">
                                  <p>Email ID</p>
                                  <span>{surveyData.dataId.emailId}</span>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 mb-3 hd-none">
                                <div className="reg-contant">
                                  <p>Survey Created By</p>
                                  <span>
                                    {surveyData.dataId.preSurveyCreatedBy}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 mb-3 hd-none">
                                <div className="reg-contant">
                                  <p>Created On</p>
                                  <span>
                                  {new Date(
                                    new Date(surveyData.dataId.preSurveyCreatedDate).getTime() - 6 * 60 * 60 * 1000 + 30 * 60 * 1000
                                  ).toLocaleString("en-IN", {
                                    timeZone: "Asia/Kolkata",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    second: "numeric",
                                  })}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="row hd-none">
                              <div className="col-md-12">
                                <div className="photo-area">
                                  <div className="row">
                                    <div className="col-md-12 ps-0">
                                      <div className="photo-heading d-flex align-items-center">
                                        Question
                                      </div>
                                      <div
                                        className="question-area"
                                        style={{
                                          maxHeight: "750px",
                                          overflowY: "overlay",
                                        }}
                                      >
                                        {surveyData.dataId.preSurvey.map(
                                          (preQuestion, index) => {
                                            const matchingPostQuestion =
                                              surveyData.dataId.postSurvey.find(
                                                (postQuestion) =>
                                                  postQuestion.question ===
                                                  preQuestion.question
                                              );
                                            return (
                                              <>
                                                <div
                                                  className="card mb-3"
                                                  key={preQuestion._id}
                                                >
                                                  <div className="card-body">
                                                    <div className="row">
                                                      <div className="col-sm-5 pe-0">
                                                        <ul className="qlist">
                                                          <li>
                                                            <span>
                                                              Question.{" "}
                                                              {index + 1}
                                                            </span>
                                                            <br />
                                                            <p>
                                                              {
                                                                preQuestion.question
                                                              }
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <span>
                                                              Pre Answer.
                                                            </span>
                                                            <br />
                                                            <p>
                                                              {preQuestion.Answer.split(
                                                                ","
                                                              ).map(
                                                                (
                                                                  item,
                                                                  index
                                                                ) => (
                                                                  <React.Fragment
                                                                    key={index}
                                                                  >
                                                                    {item}
                                                                    <br />
                                                                  </React.Fragment>
                                                                )
                                                              )}
                                                            </p>
                                                          </li>
                                                          <li>
                                                            <span>
                                                              Pre Comment.
                                                            </span>
                                                            <br />
                                                            <p>
                                                              {
                                                                preQuestion.answerdescription
                                                              }
                                                            </p>
                                                          </li>
                                                        </ul>
                                                        <br />
                                                        <br />
                                                        {matchingPostQuestion && (
                                                          <>
                                                            <ul className="qlist">
                                                              <li>
                                                                <span>
                                                                  Post Answer.
                                                                </span>
                                                                <br />
                                                                <p>
                                                                  <strong>
                                                                    {
                                                                      matchingPostQuestion.Answer
                                                                    }
                                                                  </strong>{" "}
                                                                </p>
                                                              </li>
                                                              <li>
                                                                <span>
                                                                  Post Comment.
                                                                </span>
                                                                <p>
                                                                  {
                                                                    matchingPostQuestion.answerdescription
                                                                  }
                                                                </p>
                                                              </li>
                                                            </ul>
                                                          </>
                                                        )}
                                                      </div>
                                                      <div className="col-sm-7">
                                                        <div className="row">
                                                          <div className="col-sm-6 px-1">
                                                            <div className="pre-photo">
                                                              <p className="text-black mb-2">
                                                                Pre Photo
                                                              </p>
                                                              {preImageUrls[index] ? (
                                                                <div className="photo-box">
                                                                <div className="zoom-container">
                                                                  <img src={preImageUrls[index]} alt="Pre Image" />
                                                                </div>
                                                                </div>
                                                              ) : (preQuestion.is_capture_image === true)?(
                                                                <p style={{height: '260px',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>Loading image....</p>
                                                              ):(<div>An image is not required in this section.</div>)}    
                                                             
                                                            </div>
                                                          </div>
                                                          <div className="col-sm-6 px-1">
                                                            <div className="pre-photo">
                                                              <p className="text-black  mb-2">
                                                                Post Photo
                                                              </p>

                                                              {preQuestion.is_capture_image ===
                                                              false ? (
                                                                <div>
                                                                  An image is
                                                                  not required
                                                                  in this
                                                                  section.
                                                                </div>
                                                              ) : (
                                                                <div className="photo-box">
                                                                  <img
                                                                    src="assets/images/post-placeholder.jpg"
                                                                    alt="Placeholder"
                                                                  />
                                                                </div>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </section>
                    {surveyData &&
                      surveyData.dataId.postSurvey &&
                      surveyData.dataId.postSurvey.map((postSurvey, index) => (
                        <section
                          key={index}
                          className={`border-1 one ${
                            openSection === index + 2 ? "opendiv" : "closediv"
                          } ${
                            surveyData.dataId.isFixed &&
                            index === surveyData.dataId.postSurvey.length - 1
                              ? "isFinalPost"
                              : ""
                          }`}
                        >
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <h4
                                className="photo-heading d-flex align-items-center"
                                onClick={() => toggleSection(index + 2)}
                              >
                                <span>
                                  {openSection === index + 2 ? (
                                    <FaMinus />
                                  ) : (
                                    <FaPlus />
                                  )}
                                </span>{" "}
                                {surveyData.dataId.isFixed &&
                                index ===
                                  surveyData.dataId.postSurvey.length - 1
                                  ? "Final"
                                  : "Unpaid"}{" "}
                                Post Survey Date:{" "}
                                {new Date(
                                  new Date(postSurvey.postSurveyCreatedDate).getTime() - 6 * 60 * 60 * 1000 + 30 * 60 * 1000
                                ).toLocaleString("en-IN", {
                                  
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                })}
                              </h4>
                            </div>
                            <div className="col-md-4 col-sm-6 mb-3 hd-none">
                              <div className="reg-contant">
                                <p>Registration No.</p>
                                <span>{surveyData.dataId.registrationNo} </span>
                              </div>
                            </div>

                            <div className="col-md-4 col-sm-6 mb-3 hd-none">
                              <div className="reg-contant">
                                <p>Make</p>
                                <span></span>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mb-3 hd-none">
                              <div className="reg-contant">
                                <p>Modal No.</p>
                                <span></span>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mb-3 hd-none">
                              <div className="reg-contant">
                                <p>Customer Name </p>
                                <span>{surveyData.dataId.customername}</span>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mb-3 hd-none">
                              <div className="reg-contant">
                                <p>Mobile No.</p>
                                <span>{surveyData.dataId.customermobile}</span>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mb-3 hd-none">
                              <div className="reg-contant">
                                <p>Email ID</p>
                                <span>{surveyData.dataId.emailId}</span>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mb-3 hd-none">
                              <div className="reg-contant">
                                <p>Survey Created By</p>
                                <span>{postSurvey.postSurveyCreatedBy}</span>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mb-3 hd-none">
                              <div className="reg-contant">
                                <p>Created On</p>
                                <span>
                                {new Date(
                                  new Date(postSurvey.postSurveyCreatedDate).getTime() - 6 * 60 * 60 * 1000 + 30 * 60 * 1000
                                ).toLocaleString("en-IN", {
                                  
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                })}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row hd-none">
                            <div className="col-md-12">
                              <div className="photo-area">
                                <div className="row">
                                  <div className="col-md-12 ps-0">
                                    <div className="photo-heading d-flex align-items-center">
                                      Question
                                    </div>
                                    <div
                                      className="question-area"
                                      style={{
                                        maxHeight: "750px",
                                        overflowY: "overlay",
                                      }}
                                    >
                                      {surveyData.dataId.preSurvey.map(
                                        (preQuestion, index) => {
                                          const UnpaidPostQuestion =
                                            postSurvey.Survey.find(
                                              (postQuestion) =>
                                                postQuestion.question ===
                                                preQuestion.question
                                            );
                                          return (
                                            <>
                                              <div
                                                className="card mb-3"
                                                key={preQuestion._id}
                                              >
                                                <div className="card-body">
                                                  <div className="row">
                                                    <div className="col-sm-5 pe-0">
                                                      <ul className="qlist">
                                                        <li>
                                                          <span>
                                                            Question.{" "}
                                                            {index + 1}
                                                          </span>
                                                          <br />
                                                          <p>
                                                            {
                                                              preQuestion.question
                                                            }
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <span>
                                                            Pre Answer.
                                                          </span>
                                                          <br />
                                                          <p>
                                                            {preQuestion.Answer.split(
                                                              ","
                                                            ).map(
                                                              (item, index) => (
                                                                <React.Fragment
                                                                  key={index}
                                                                >
                                                                  {item}
                                                                  <br />
                                                                </React.Fragment>
                                                              )
                                                            )}
                                                          </p>
                                                        </li>
                                                        <li>
                                                          <span>
                                                            Post Comment.
                                                          </span>
                                                          <br />
                                                          <p>
                                                            {
                                                              preQuestion.answerdescription
                                                            }
                                                          </p>
                                                        </li>
                                                      </ul>
                                                      <br />
                                                      <br />
                                                      {UnpaidPostQuestion && (
                                                        <>
                                                          <ul className="qlist">
                                                            <li>
                                                              <span>
                                                                Post Answer.
                                                              </span>
                                                              <br />
                                                              <p>
                                                                {UnpaidPostQuestion.Answer.split(
                                                                  ","
                                                                ).map(
                                                                  (
                                                                    item,
                                                                    index
                                                                  ) => (
                                                                    <React.Fragment
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      {item}
                                                                      <br />
                                                                    </React.Fragment>
                                                                  )
                                                                )}
                                                              </p>
                                                            </li>
                                                            <li>
                                                              <span>
                                                                Post Comment.
                                                              </span>
                                                              <br />
                                                              <p>
                                                                {
                                                                  UnpaidPostQuestion.answerdescription
                                                                }
                                                              </p>
                                                            </li>
                                                          </ul>
                                                        </>
                                                      )}
                                                    </div>
                                                    <div className="col-sm-7">
                                                      <div className="row">
                                                        <div className="col-sm-6 px-1">
                                                          <div className="pre-photo">
                                                            <p className="text-black mb-2">
                                                              Pre Photo
                                                            </p>
                                                            {preImageUrls[index] ? (
                                                               <div className="photo-box">
                                                              <div className="zoom-container">
                                                                <img src={preImageUrls[index]} alt="Pre Image" />
                                                              </div>
                                                              </div>
                                                            ) : (preQuestion.is_capture_image === true)?(
                                                              <p style={{height: '260px',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>Loading image....</p>
                                                            ):(<div>An image is not required in this section.</div>)}    
                                                          </div>
                                                        </div>
                                                        <div className="col-sm-6 px-1">
                                                          <div className="pre-photo">
                                                            <p className="text-black  mb-2">
                                                              Post Photo
                                                            </p>
                                                            {UnpaidPostQuestion.is_capture_image === true && UnpaidPostQuestion.imagepath ? (
                                                                <div className="photo-box">
                                                                <div className="zoom-container">
                                                                  <img
                                                                      src={`${baseImageUrl}${UnpaidPostQuestion.imagepath}`}
                                                                      alt="Post Image"
                                                                  />
                                                              </div>
                                                              </div>
                                                            ) :(UnpaidPostQuestion.is_capture_image === true)?(<p style={{height: '260px',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>Loading image....</p>): (
                                                              <div>
                                                                {preQuestion.is_capture_image === false ? (
                                                                  <div>An image is not required in this section.</div>
                                                                ) : (
                                                                  <div className="zoom-container"><img src="assets/images/post-placeholder.jpg" alt="Placeholder" /></div>
                                                                )}
                                                              </div>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnpaidReport;
