import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { API_BASE_URL } from '../../constant/apiconstant';

const Question = () => {
  const [selectAgency, setSelectedAgency] = useState("");

  const [questions, setQuestions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("jwt");
  const accountId = localStorage.getItem("accountId");
  const role = localStorage.getItem("role");
  const bearerToken = token;
  const [agentData, setAgentData] = useState([]);

  useEffect(() => {
    // Fetch agents data
    axios
      .get(
        `${API_BASE_URL}account/getAlldata?limit=100&skip=0`,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      )
      .then((response) => {
        console.log("Agents Data:", response.data);
        setAgentData(response.data);
      })
      .catch((error) => console.error("Error fetching agent data:", error));
  }, [bearerToken]);

  useEffect(() => {
    fetchCategories();
  }, [token]);

  const fetchCategories = async () => {
    try {
      setLoadingCategories(true);
      const response = await axios.get(
        `${API_BASE_URL}category`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCategories(response.data);
      setLoadingCategories(false);
    } catch (error) {
      setError("Error fetching categories");
      setLoadingCategories(false);
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  useEffect(() => {
    if (selectedCategory !== "") {
      fetchQuestions();
    }
  }, [selectedCategory, token]);

  const fetchQuestions = async () => {
    try {
      setLoadingQuestions(true);
      const response = await axios.get(
        `${API_BASE_URL}question/categoryid/${selectedCategory}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setQuestions(response.data.reverse());
      //console.warn("llllllllllllllllllll", response.data);
      setLoadingQuestions(false);
    } catch (error) {
      setError("Error fetching questions");
      setLoadingQuestions(false);
    }
  };

  const searchBoxStyles = {
    container: {
      textAlign: "right",
      marginBottom: "10px", // Optional, add some bottom margin
    },
    input: {
      borderRadius: "4px",
      padding: "5px 10px",
      border: "1px solid #ccc",
      boxShadow: "none",
      float: "left", // Set the desired width of the search box
      marginBottom: "10px", // Optional, add some right margin to the search box
      marginTop: "10px", // Optional, add some right margin to the search box
    },
  };

  console.log(questions);

  // Function to delete a question
  const deleteQuestion = (questionId) => {
    if (window.confirm("Are you sure you want to delete this question?")) {
      axios
        .delete(
          `${API_BASE_URL}question/delete/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // Filter out the deleted question from the state
          setQuestions(questions.filter((q) => q._id !== questionId));
        })
        .catch((error) => {
          console.error("Error deleting question:", error);
        });
    }
  };

  // const filteredCategories = Categories.filter(
  //   (item) => selectedAgencyId === "" || item.accountId === selectedAgencyId
  // );
  const navigate = useNavigate();

  const handleEditQuestion = (_id) => {
    // Use the navigate function to go to the desired route
    navigate(`/edit-question?_id=${_id}`);
  };
  return (
    <div>
      <Helmet>
        <title>Question List | Pre-Post Survey</title>
      </Helmet>
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Question</h4>

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">User</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              {role === "superadmin" && (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-4">
                            <select
                              name="accountId"
                              className="form-control"
                              value={selectAgency}
                              onChange={(e) => {
                                setSelectedAgency(e.target.value);
                                setSelectedCategory("");
                              }}
                              required
                              style={searchBoxStyles.input}
                            >
                              <option value="">Select Agency</option>
                              {agentData.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.accountName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-lg-6">
                            {/* Dropdown to select category */}
                            <select
                              value={selectedCategory}
                              onChange={handleCategoryChange}
                              style={searchBoxStyles.input}
                            >
                              <option value="">
                                Select a agency to show category list
                              </option>
                              {categories
                                .filter(
                                  (category) =>
                                    category.accountId === selectAgency
                                )
                                .map((category) => (
                                  <option
                                    key={category._id}
                                    value={category._id}
                                  >
                                    {category.categoryName}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="col-lg-2">
                            {role === "Admin" && (
                              <Link
                                to="/add-question"
                                className="btn btn-outline-primary btn-sm waves-effect waves-light addusers"
                              >
                                <i className="fa fa-plus"></i> Add Question
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {role === "Admin" && (
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-4">
                          {/* Dropdown to select category */}
                          <select
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                            style={searchBoxStyles.input}
                          >
                            <option value="">Select a category</option>
                            {categories
                              .filter(
                                (category) => category.accountId === accountId
                              )
                              .map((category) => (
                                <option key={category._id} value={category._id}>
                                  {category.categoryName}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-lg-6"></div>
                        <div className="col-lg-2">
                          {role === "Admin" && (
                            <Link
                              to="/add-question"
                              className="btn btn-outline-primary btn-sm waves-effect waves-light addusers"
                            >
                              <i className="fa fa-plus"></i> Add Question
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Render the list of questions */}

              <div className="row">
                <div className="col-md-12">
                  {questions.length > 0 ? (
                    questions.map((question, index) => (
                      <div key={question._id} className="card mb-3">
                        <div className="card-body">
                          <h5 className="card-title">
                            Q {index + 1}: {question.question}
                          </h5>
                          {question.is_capture_image === true &&
                            question.imagetag && (
                              <div className="tag">
                                <span>Tag: {question.imagetag}</span>
                              </div>
                            )}
                          <div className="input-container">
                            <div className="question-type">
                              Question Type: {question.questionType}
                            </div>
                            {/* Rest of your code for rendering question types */}
                          </div>
                          {role === "Admin" && (
                          <div className="edit-delete-buttons">
                            <button
                              className="btn btn-outline-primary btn-sm"
                              onClick={() => handleEditQuestion(question._id)}
                            >
                              Edit
                            </button>
                            <span> </span>
                            <button
                              className="btn btn-outline-danger btn-sm"
                              onClick={() => deleteQuestion(question._id)}
                            >
                              Delete
                            </button>
                          </div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="card mb-3">
                      <div className="card-body">
                        {role === "superadmin" && (
                          <h5 className="card-title">
                            Select Agency and Category to show the question
                            list.
                          </h5>
                        )}
                        {role === "Admin" && (
                          <h5 className="card-title">
                            Select Category to show the question list.
                          </h5>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightbar-overlay"></div>
    </div>
  );
};
export default Question;
