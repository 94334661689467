
export const API_BASE_URL = "https://prepost.srmstar.in/"; 
export const API_BASE_URL_FRONT = "https://prepost.srmstar.in/"; 
export const API_TWILIO_URL = "https://api.twilio.com/2010-04-01/Accounts/"; 
export const TWILIO_ACCOUNT_SID = "ACc98844ef726518cd440a626ffdce11ec";
export const TWILIO_AUTH_TOKEN = "a655bbd1eaad9076bd44cddf5ab84fb8";




