import { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/SurveyPopup.css";
import { API_BASE_URL,API_BASE_URL_FRONT,TWILIO_ACCOUNT_SID,TWILIO_AUTH_TOKEN, API_TWILIO_URL } from '../../constant/apiconstant';


const SurveyPopup = ({ data, onClose }) => {
  const [preImageUrls, setPreImageUrls] = useState([]);
  const [postImageUrls, setPostImageUrls] = useState([]);
  const [sendingStatus, setSendingStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [requiredApprovalTools, setRequiredApprovalTools] = useState([]);


  useEffect(() => {
    // Fetch data from the Setting Get APi to check the value of the require tool.
    axios
      .get(`${API_BASE_URL}setting`)
      .then((response) => {
        // Handle the response data here
        console.warn("Setting data:", response.data);
  
        // Retrieve the account ID from local storage
        const accountID = localStorage.getItem("accountId");
        console.warn("accountId from localStorage:", accountID);
  
        if (response.data && Array.isArray(response.data.data)) {
          // Filter the settings based on the accountID
          const filteredSettings = response.data.data.filter(item => item.accountId === accountID);
          const requiredApprovalTools = filteredSettings.map(item => item.required_approval_tool);
          setRequiredApprovalTools(requiredApprovalTools);

        // You can update your component's state or perform any other necessary actions with the requiredApprovalTools array.
        console.warn("required_approval_tool values:", requiredApprovalTools);

          // You can update your component's state or perform any other necessary actions with the filtered settings.
          console.warn("Filtered Data:", filteredSettings);
        } else {
          console.error("API data structure is not as expected.");
        }
      })
      .catch((error) => {
        // Handle any errors that occur during the API request
        console.error("Error fetching data:", error);
      });
  }, []);

  
  
  useEffect(() => {
    if (data && data.preSurvey && data.postSurvey) {
      const preSurveyImageUrls = data.preSurvey.map((question) =>
        question.imagepath
          ? staticImagePath + question.imagepath
          : "assets/images/post-placeholder.jpg"
      );
      const postSurveyImageUrls = data.postSurvey.map((question) =>
        question.imagepath
          ? staticImagePath + question.imagepath
          : "assets/images/post-placeholder.jpg"
      );

      setPreImageUrls(preSurveyImageUrls);
      setPostImageUrls(postSurveyImageUrls);
    }
  }, [data]);

  if (!data) return null;
  const staticImagePath = `${API_BASE_URL}survey-image/`;
  const isPreSurveyComplete = data.preSurvey.length > 0;
  const isPostSurveyComplete = data.postSurvey.length > 0;
  let surveyStatus = "";
  let surveyStatusMail = "";
  if (isPreSurveyComplete && isPostSurveyComplete) {
    surveyStatus = "Complete Survey";
    surveyStatusMail = "2";
  } else if (isPreSurveyComplete) {
    surveyStatusMail = "2";
    surveyStatus = "Pre-Survey";
  }

  const surveyId = data._id;

  const handleSendMail = async () => {
    const url = `${API_BASE_URL}mail/html-email`;
    const queryParameters = {
      name: encodeURIComponent(data.customername),
      emailId: encodeURIComponent(data.emailId),
      subject: encodeURIComponent(`${surveyStatus} Report`),
      mailContent: encodeURIComponent(
        `Please find the ${surveyStatus} report in the below link`
      ),
      reportUrl: encodeURIComponent(
        `${API_BASE_URL}reports?surveyId=${data._id}`
      ),
    };

    const queryString = Object.keys(queryParameters)
      .map((key) => `${key}=${queryParameters[key]}`)
      .join("&");

    const fullUrl = `${url}?${queryString}`;

    try {
      setSendingStatus("Sending");
      const response = await axios.post(fullUrl);

      // Handle success, maybe show a success message
      console.log("Mail sent:", response.data);

      if (response.data === "success") {
        setSendingStatus("Sent");
        await updateSurveyStatus(surveyId, surveyStatusMail);
      }
    } catch (error) {
      // Handle error, show an error message
      console.error("Error sending mail:", error);
      setSendingStatus("");
    }
  };

  const updateSurveyStatus = async (surveyId, surveyStatusMail) => {
    try {
      const updateData = {
        mailstatus: surveyStatusMail,
      };

      const response = await axios.put(
        `${API_BASE_URL}survey/updatestatus/${surveyId}`,
        updateData,
        {
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      );

      // Handle success, maybe show a success message
      console.log("Survey status updated:", response.data);
    } catch (error) {
      // Handle error, show an error message
      console.error("Error updating survey status:", error);
    }
  };

  const handleUpdateSurveyStatus = async (surveyId, surveyStatus) => {
    const url = `${API_BASE_URL}survey/updatestatus/${surveyId}`;

    const updateData = {
      mailstatus: surveyStatus,
    };

    try {
      const response = await axios.post(url, updateData);

      // Handle success, maybe show a success message
      console.log("Survey status updated:", response.data);
    } catch (error) {
      // Handle error, show an error message
      console.error("Error updating survey status:", error);
    }
  };

  const sendMessage = () => {
    

    const surveyReportLink = `${API_BASE_URL_FRONT}reports?surveyId=${data._id}`;

    const bodyText = `Hi, ${data.customername}\n\nPlease find the ${surveyStatus} survey report by clicking the link:\n ${surveyReportLink}.`;

    axios
      .post(
        `${API_TWILIO_URL}${TWILIO_ACCOUNT_SID}/Messages.json`,
        {
          From: "whatsapp:+14155238886",
          Body: bodyText,
          To: "whatsapp:+916280768289",
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          auth: {
            username: TWILIO_ACCOUNT_SID,
            password: TWILIO_AUTH_TOKEN,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setResponseMessage("Message sent successfully!");
      })
      .catch((error) => {
        console.error(error);
        setResponseMessage("Message sending failed!");
      });
  };

  console.log(data);

  const lastPostSurvey = data.postSurvey[data.postSurvey.length - 1];
  console.warn(".......................................", lastPostSurvey);

  if (lastPostSurvey) {
    // Get the image path for the last post-survey question
    const lastPostSurveyImagePath = lastPostSurvey.Survey.map(
      (question) => question.imagepath
    );

    return (
      <div className="survey-popup">
        <div className="headfix">
          <div className="modal-header">
            <h5 className="modal-title" id="transaction-detailModalLabel">
              Survey ID <strong>{data._id}</strong>{" "}
            </h5>
            <i
              className="fa fa-times fa-lg"
              aria-hidden="true"
              type="button"
              onClick={onClose}
            ></i>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 mb-3">
              <div className="reg-contant">
                <p>Registration No.</p>
                <span>{data.registrationNo}</span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-3">
              <div className="reg-contant">
                <p>Make</p>
                <span>{data.make}</span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-3">
              <div className="reg-contant">
                <p>Modal No.</p>
                <span>{data.model}</span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-3">
              <div className="reg-contant">
                <p>Customer Name </p>
                <span>{data.customername}</span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-3">
              <div className="reg-contant">
                <p>Mobile No.</p>
                <span>{data.customermobile}</span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-3">
              <div className="reg-contant">
                <p>Email ID</p>
                <span>{data.emailId}</span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-3">
              <div className="reg-contant">
                <p>Pre Survey Done by</p>
                <span>{data.preSurveyCreatedBy}</span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-3">
              <div className="reg-contant">
                <p>Post Survey Done by</p>
                <span>{data.postSurveyCreatedBy}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-body">
          <div className="photo-area">
            <div className="photo-heading d-flex align-items-center">
              Question
            </div>
            <div className="question-area">
              <div className="card mb-3">
                <div className="card-body">
                  {data.preSurvey.map((question, index) => (
                    <div className="row" key={index}>
                      <div className="col-sm-5 pe-0">
                        <ul className="qlist">
                          <li>
                            <span>Question. {index + 1}</span>
                            <br />
                            <p>{question.question}</p>
                          </li>
                          <li>
                            <span>Pre Answer.</span>
                            <br />
                            <p>
                              <strong>{question.Answer}</strong>{" "}
                            </p>
                          </li>
                          <li>
                            <span>Pre Comment.</span>
                            <br />
                            <p>{question.answerdescription}</p>
                          </li>
                          {data.postSurvey[index] && (
                            <>
                              <hr />
                              <br />
                              <li>
                                <span>Post Answer.</span> <br />
                                <p>
                                  <strong>
                                    {lastPostSurvey.Survey[index].Answer}
                                  </strong>{" "}
                                </p>
                              </li>
                              <li>
                                <span>Post Comment.</span> <br />
                                <p>
                                  {" "}
                                  {
                                    lastPostSurvey.Survey[index]
                                      .answerdescription
                                  }
                                </p>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>

                      <div className="col-sm-7">
                        <div className="row">
                          <div className="col-sm-6 px-1">
                            <div className="pre-photo">
                              <p className="text-black mb-2">Pre Photo</p>

                              {question.is_capture_image === false ? (
                                <p>An image is not required in this section.</p>
                              ) : (
                                <div className="photo-box">
                                  <img
                                    src={preImageUrls[index]} // Use the extracted pre image URL
                                    alt={question.imagetag}
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-sm-6 px-1">
                            <div className="pre-photo">
                              <p className="text-black mb-2">Post Photo</p>

                              {question.is_capture_image === false ? (
                                <p>An image is not required in this section.</p>
                              ) : (
                                <div className="photo-box">
                                  <img
                                    src={
                                      lastPostSurveyImagePath[index]
                                        ? staticImagePath +
                                          lastPostSurveyImagePath[index] // Use the static path with the image URL
                                        : "assets/images/post-placeholder.jpg" // Default placeholder image
                                    }
                                    alt="Last Post Photo"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-warning px-4 btn-rounded waves-effect modal-btn"
            data-bs-dismiss="modal"
            onClick={onClose}
          >
            Cancelled
          </button>

          <p>{responseMessage}</p>

          {data.customername && data.emailId && requiredApprovalTools.includes(true) ? (
            <button
              type="button"
              className="btn btn-primary px-4 btn-rounded waves-effect modal-btn"
              onClick={handleSendMail}
              disabled={sendingStatus === "Sending"} // Disable the button while sending
            >
              {sendingStatus === "Sending"
                ? "Sending..."
                : sendingStatus === "Sent"
                ? "Sent Successfully"
                : "Send Mail"}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  } else {
    // Handle the case where there is no last post-survey data
    return (
      <div className="survey-popup">
        <div className="headfix">
          <div className="modal-header">
            <h5 className="modal-title" id="transaction-detailModalLabel">
              Survey ID <strong>{data._id}</strong>{" "}
            </h5>
            <i
              className="fa fa-times fa-lg"
              aria-hidden="true"
              type="button"
              onClick={onClose}
            ></i>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 mb-3">
              <div className="reg-contant">
                <p>Registration No.</p>
                <span>{data.registrationNo}</span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-3">
              <div className="reg-contant">
                <p>Make</p>
                <span>{data.make}</span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-3">
              <div className="reg-contant">
                <p>Modal No.</p>
                <span>{data.model}</span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-3">
              <div className="reg-contant">
                <p>Customer Name </p>
                <span>{data.customername}</span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-3">
              <div className="reg-contant">
                <p>Mobile No.</p>
                <span>{data.customermobile}</span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-3">
              <div className="reg-contant">
                <p>Email ID</p>
                <span>{data.emailId}</span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-3">
              <div className="reg-contant">
                <p>Pre Survey Done by</p>
                <span>{data.preSurveyCreatedBy}</span>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-3">
              <div className="reg-contant">
                <p>Post Survey Done by</p>
                <span>{data.postSurveyCreatedBy}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-body">
          <div className="photo-area">
            <div className="photo-heading d-flex align-items-center">
              Question
            </div>
            <div className="question-area">
              <div className="card mb-3">
                <div className="card-body">
                  {data.preSurvey.map((question, index) => (
                    <div className="row" key={index}>
                      <div className="col-sm-5 pe-0">
                        <ul className="qlist">
                          <li>
                            <span>Question. {index + 1}</span>
                            <br />
                            <p>{question.question}</p>
                          </li>
                          <li>
                            <span>Pre Answer.</span>
                            <br />
                            <p>
                              <strong>{question.Answer}</strong>{" "}
                            </p>
                          </li>
                          <li>
                            <span>Pre Comment.</span>
                            <br />
                            <p>{question.answerdescription}</p>
                          </li>
                        </ul>
                      </div>
                      <div className="col-sm-7">
                        <div className="row">
                          <div className="col-sm-6 px-1">
                            <div className="pre-photo">
                              <p className="text-black mb-2">Pre Photo</p>

                              {question.is_capture_image === false ? (
                                <p>An image is not required in this section.</p>
                              ) : (
                                <div className="photo-box">
                                  <img
                                    src={preImageUrls[index]}
                                    alt={question.imagetag}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6 px-1">
                            <div className="pre-photo">
                              <p className="text-black mb-2">Post Photo</p>

                              {question.is_capture_image === false ? (
                                <p>An image is not required in this section.</p>
                              ) : (
                                <div className="photo-box">
                                  <img
                                    src="assets/images/post-placeholder.jpg"
                                    alt="Last Post Photo"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-warning px-4 btn-rounded waves-effect modal-btn"
            data-bs-dismiss="modal"
            onClick={onClose}
          >
            Cancelled
          </button>
          <p>{responseMessage}</p>
        </div>
      </div>
    );
  }
};

export default SurveyPopup;
