import React, { useState, useEffect, StrictMode } from "react";

import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import SurveyPopup from "./SurveyPop";
import axios from "axios";
import "../../styles/SurveyPopup.css";
import jwtDecode from "jwt-decode";
import { Helmet } from 'react-helmet';
import { API_BASE_URL } from '../../constant/apiconstant';

const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
    },
  },
};

const Survey = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [data, setData] = useState([]);
  const [surveyData, setSurveyData] = useState([]);
  const [selectedAgencyId, setSelectedAgencyId] = useState("");
  const [isRoleSuperAdmin, setIsRoleSuperAdmin] = useState(false);


  
  const handleEyeButtonClick = (survey) => {
    setSelectedSurvey(survey);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedSurvey(null);
  };

  const columns = [
    {
      name: "S No.",
      selector: (_, index) => index + 1,
      sortable: false,
      width: "75px",
    },

    {
      name: "Registration No.",
      selector: (row) => row.registrationNo,
      sortable: true,
    },
    {
      name: "Contact No.",
      selector: (row) => (row.customermobile ? row.customermobile : "N/A"),
      sortable: true,
    },
    {
      name: "Email ID",
      selector: (row) => row.emailId,
      sortable: true,
    },
    {
      name: "Mail Status",
      selector: "mailstatus",
      cell: (row) => {
        const isPreSurveyComplete = row.preSurvey.length > 0;
        const isPostSurveyComplete = row.postSurvey.length > 0;
        let surveyStatus = '';
        let buttonClass = '';
        
        // Determine the survey status
        if (isPreSurveyComplete && isPostSurveyComplete) {
          surveyStatus = 'Complete Survey';
          buttonClass = 'btn-outline-success';
        } else if (isPreSurveyComplete) {
          buttonClass = 'btn-outline-warning';
          surveyStatus = 'Pre-Survey';
        }
    
        // Check if the survey status is "Pre-Survey" and hide mail status if true
        if (surveyStatus === 'Pre-Survey') {
          return null;
        }
    
        // Otherwise, display the mail status
        if (row.mailstatus === "1") {
          return "Email Sent";
        } else if (row.mailstatus === "2") {
          return "Email Sent";
        } else {
          return "No Email Sent";
        }
      },
      sortable: true,
    },
    
    
    {
      name: "Survey Status",
      cell: (row) => {
        const isPreSurveyComplete = row.preSurvey.length > 0;
        const isPostSurveyComplete = row.postSurvey.length > 0;
        let surveyStatus = '';
        let buttonClass = '';
        let dateStatus = '';
        if (isPreSurveyComplete && isPostSurveyComplete) {
          surveyStatus = 'Complete Survey';
          buttonClass = 'btn-outline-success';
        } else if (isPreSurveyComplete) {
          buttonClass = 'btn-outline-warning';
          surveyStatus = 'Pre-Survey';
        }
        return (
          <span className={`btn btn-sm ${buttonClass}`}>
           {surveyStatus}
         </span>
        );
      },
      width: "150px",
    },
    {
      name: "Date",
      selector: (row) => new Date(row.PostSurveyUpdatedDate).toLocaleDateString("en-IN"),
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => (
        <div>
          <button
            className="btn btn-outline-primary btn-sm waves-effect waves-light"
            title="View Survey"
            onClick={() => handleEyeButtonClick(row)}
          >
            <i className="far fa-eye"></i>
          </button>{" "} 

          <a
            href={`/reports?surveyId=${row._id}`}
            className="btn btn-outline-primary btn-sm waves-effect waves-light"
            title="View Reports"
            target="_blank"
          >
            <i class="fas fa-poll"></i>
          </a>

          <a
            href={`/unpaid-report?surveyId=${row._id}`}
            className="btn btn-outline-primary btn-sm waves-effect waves-light"
            title="View Unpaid Reports"
            target="_blank"
          >
          <i class="fa fa-id-card"></i>
          </a>
        </div>
      ),
      width: "150px",
    },
  ];

  
  // useEffect hook to fetch data from the API
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}survey/getAlldata?limit=1000&skip=0&preSurvey=false`)
      .then((response) => {
        // Assuming the response.data contains an array under the 'data' key
        const token = localStorage.getItem("jwt");
        const decodedToken = jwtDecode(token);
        const loggedInUserId = decodedToken.accountId;
        //console.warn("sssssssssssssss", loggedInUserId);
        // Extract _id from the decoded token
        setSurveyData(response.data.data);
        const allData = response.data.data;
        //console.warn("aaaaaaaaaaaaaaaaa", response.data.data);
        setLoading(false);
        const userRole = decodedToken.role;
        setIsRoleSuperAdmin(userRole === "superadmin");

        if (loggedInUserId) {
          // Filter the data based on the logged-in user's accountId

          const filteredData = allData.filter(
            (item) => item.accountId === loggedInUserId
          );
          console.warn("filet", filteredData);
          setSurveyData(filteredData);
        } else {
          setSurveyData(allData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  console.warn("ssrrrr",surveyData)



  const customFilter = (rows, searchTerm) => {
    return rows.filter((row) =>
      row.registrationNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.customermobile.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.emailId.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
  const searchBoxStyles = {
    container: {
      textAlign: "right",
      marginBottom: "10px", // Optional, add some bottom margin
    },
    input: {
      borderRadius: "4px",
      padding: "5px 10px",
      border: "1px solid #ccc",
      boxShadow: "none",
      float: "left", // Set the desired width of the search box
      marginBottom: "10px", // Optional, add some right margin to the search box
      marginTop: "10px", // Optional, add some right margin to the search box
    },
  };

  const [agentData, setAgentData] = useState([]);
  const token = localStorage.getItem("jwt");
  useEffect(() => {
    // Fetch agents data
    axios
      .get(
        `${API_BASE_URL}account/getAlldata?limit=1000&skip=0`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Agents Data:", response.data);
        setAgentData(response.data);
      })
      .catch((error) => console.error("Error fetching agent data:", error));
  }, [token]);

  
  const filteredSurveyData = surveyData.filter(
    (item) => selectedAgencyId === "" || item.accountId === selectedAgencyId
  );

  return (
    <div>
      <Helmet>
        <title>Survey List | Pre-Post Survey</title>
      </Helmet> 
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Survey List</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>

                        <li className="breadcrumb-item active">Survey List</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-6">
                          {showPopup && (
                            <SurveyPopup
                              data={selectedSurvey}
                              onClose={handleClosePopup}
                            />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <input
                            type="text"
                            placeholder="Search..."
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            style={searchBoxStyles.input}
                          />
                        </div>
                        <div className="col-lg-6"></div>
                        <div className="col-lg-2">
                          {isRoleSuperAdmin ? (
                            <div className="select-container">
                            <select
                            name="accountId"
                            className="form-control"
                            style={searchBoxStyles.input}
                            value={selectedAgencyId}
                            onChange={(e) => setSelectedAgencyId(e.target.value)}
                          >
                            <option value="">Select Agency</option>
                            {agentData.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.accountName}
                              </option>
                            ))}
                          </select>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="table-responsive">
                      <DataTable
                      columns={columns}
                      data={
                        searchText.trim() !== ""
                          ? customFilter(filteredSurveyData, searchText)
                          : filteredSurveyData
                      }
                      customStyles={customStyles}
                      pagination
                      searchable
                    />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightbar-overlay"></div>
    </div>
  );
};

export default Survey;

