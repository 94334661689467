import React, { useEffect, useState } from "react";
import SuperadminList from "../Component/SuperadminList";
import AdminList from "../Component/AdminList";
import { Helmet } from 'react-helmet';

const Home = () => {
  const [role, setRole] = useState(""); // State to store the role

  useEffect(() => {
    // Retrieve the role from local storage
    const storedRole = localStorage.getItem("role");
    setRole(storedRole); // Set the role in state
  }, []);

  // Conditionally render ComponentA or ComponentB based on the role
  return (
    <div id="layout-wrapper">
      <Helmet>
      <title>Dashboard | Pre-Post Survey</title>
    </Helmet> 
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              {role === "superadmin" ? (
                <SuperadminList />
              ) : role === "Admin" ? (
                <AdminList />
              ) : (
                // Default content if no role is found or if it's neither "superadmin" nor "admin"
                <p>No component to render for this role</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;


