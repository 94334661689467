import React, { useState } from "react";

const NumberInput = ({ value, onChange, placeholder }) => {
  const [formattedValue, setFormattedValue] = useState(value);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    if (/^\d{0,6}$/.test(newValue)) {
      setFormattedValue(newValue);
      onChange(newValue);
    }
  };

  return (
    <input
      type="text"
      className="form-control"
      autoComplete="off"
      placeholder={placeholder}
      value={formattedValue}
      onChange={handleInputChange}
      required
    />
  );
};

export default NumberInput;
