import React, { useState, useEffect, StrictMode } from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import SideBarManu from "../SideBarMenu";
import axios from "axios";
import DataTable from "react-data-table-component";
import jwtDecode from "jwt-decode";
import * as apiConstants from "../../constant/apiconstant";
import { Helmet } from 'react-helmet';
import { API_BASE_URL } from '../../constant/apiconstant';


const User = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");

  

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      // Get the access token from the local storage
      const token = localStorage.getItem("jwt");
      //console.warn("11111111111",token)
      const decodedToken = jwtDecode(token);
      const loggedInUserId = decodedToken.accountId; // Extract _id from the decoded token
      //console.warn("qwwwwwwww",loggedInUserId)
      const response = await axios.get(`${API_BASE_URL}user/getAlldata?limit=100&skip=1`);
      const allData = response.data; // Fetch all data from the API
       // console.warn("22222222222222",allData)
      // Filter the data based on the logged-in user ID
      if (loggedInUserId) {
        // Filter the data based on the logged-in user's accountId
        const filteredData = allData.filter(
          (item) => item.accountId === loggedInUserId
        );
        setData(filteredData); 
      } else {
        setData(allData); 
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

  };


  useEffect(() => {
    fetchData(); // Call the function to fetch data when the component mounts
  }, []);
  const handleDelete = async (_id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (!confirmDelete) {
      return; // If the user clicks Cancel, do nothing
    }
    try {
      // Send a DELETE request to the API using axios
      await axios.delete(`${API_BASE_URL}user/delete/${_id}`);
      // Once the account is deleted, you can fetch the updated data again or remove the deleted row from the local state if you have pagination.
      // For simplicity, we will refetch the data here.
      setData((prevData) => prevData.filter((item) => item._id !== _id));
      fetchData();
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

 
  const columns = [
    {
      name: "S.No",
      cell: (a, i) => i + 1,
      sortable: true,
      
    },
    {
      name: "Agency Name",
      selector: (row) => row.accountName,
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => row.username,
      sortable: true,
    },
    // {
    //   name: 'Logo',
    //   selector: row => row.logo,
    //   sortable: true,
    //   cell: row => <img src={staticImagePath + row.logo} alt="Image from API" height="50" />,

    // },

    {
      name: "Is Active",
      selector: (row) => row.isActive,
      sortable: true,
      cell: (row) => (<button
        className={`btn btn-sm ${row.isActive ? 'btn-outline-success' : 'btn-outline-danger'}`}
      >
        {row.isActive ? 'Active' : 'Inactive'}
      </button>),
    },
    {
      name: "Role",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => new Date(row.created_date).toLocaleDateString("en-In"),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link
            to={`/edit-user?_id=${row._id}`}
            className="btn btn-outline-primary btn-sm waves-effect waves-light"
            title="Edit Agency"
          >
            <i className="fas fa-edit"></i>
          </Link>{" "}
          <Link
            to="#"
            className="btn btn-outline-primary btn-sm waves-effect waves-light"
            title="Delete Agency"
            onClick={() => handleDelete(row._id)}
          >
            <i className="fa fa-trash"></i>
          </Link>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  const customFilter = (rows, searchTerm) => {
    return rows.filter(
      (row) => row.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.accountName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.role.toLowerCase().includes(searchTerm.toLowerCase())
      
    );
  };

  const searchBoxStyles = {
    container: {
      textAlign: "right",
      marginBottom: "10px", // Optional, add some bottom margin
    },
    input: {
      borderRadius: "4px",
      padding: "5px 10px",
      border: "1px solid #ccc",
      boxShadow: "none",
      float: "left", // Set the desired width of the search box
      marginBottom: "10px", // Optional, add some right margin to the search box
      marginTop: "10px", // Optional, add some right margin to the search box
    },
  };

  console.log(data);


  return (
    <div>
      <Helmet>
        <title>User List | Pre-Post Survey</title>
      </Helmet> 
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">User</h4>
                  
                    <div className="page-title-right">
                    
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to ="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">User</li>
                        
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                    
                      <div className="row">
                        <div className="col-lg-4">
                        <input
                          type="text"
                          placeholder="Search..."
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          style={searchBoxStyles.input}
                        />
                        </div>
                        <div className="col-lg-6"></div>
                        <div className="col-lg-2">
                          <Link
                            to="/add-user"
                            className="btn btn-outline-primary btn-sm waves-effect waves-light addusers">
                            <i className="fa fa-plus"></i> Add User
                          </Link>
                        </div>
                      </div>
                      <div className="table-responsive">
                        
                        <DataTable
                          columns={columns}
                          data={
                            searchText.trim() !== ""
                              ? customFilter(data, searchText)
                              : data
                          }
                          pagination
                          searchable
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightbar-overlay"></div>
    </div>
  );
};

export default User;
