import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { Link } from "react-router-dom";
import { API_BASE_URL } from '../constant/apiconstant';


const UserCounts = () => {
    const [totalUserCountTitle, setTotalUserCountTitle] = useState("Total Users");
    const navigate = useNavigate();
    const token = localStorage.getItem("jwt");
    console.log(token);
    if (!token) {
      localStorage.clear();
      navigate("/login");
    }
    // Decode the token to access its payload
    const decodedToken = jwtDecode(token);
    console.log(decodedToken);
    // Access data from the decoded token
    const accountId = decodedToken.accountId ? decodedToken.accountId : null;
    const username = decodedToken.username;
    const role = decodedToken.role;
    localStorage.setItem("accountId",accountId);
    localStorage.setItem("username",username);
    localStorage.setItem("role",role);

    const [totalCount, setTotalCount] = useState(0);
    const config = {
      headers: {
       "content-type": "multipart/form-data",
       "Accept": "*/*",
        "Authorization": `Bearer ${token}`,
      },
    };

    useEffect(() => {
      // Fetch data from the API
      const fetchData = async () => {
        try {
          let apiUrl = `${API_BASE_URL}user/getAlldata?limit=1000&skip=0`;
    
          if (accountId) {
            apiUrl = `${API_BASE_URL}user/getAlldataByAccountId/${accountId}?accountId=${accountId}&limit=10000&skip=0`;
            setTotalUserCountTitle("Total Agents");
          }
    
          const response = await fetch(apiUrl, config);
          
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const data = await response.json();
          const totalCountFromAPI = data.length-1;
          setTotalCount(totalCountFromAPI);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }, [accountId]);

    

  return (
    
        <div className="col-lg-3 col-md-6 col-sm-6">
        <Link to="/user">
        <div className="card mini-stats-wid">
            <div className="card-body">
                <div className="d-flex">
                    <div className="flex-grow-1">
                        <p className="text-muted fw-medium">{totalUserCountTitle}</p>
                        <h4 className="mb-0">{totalCount}</h4>
                    </div>
                    <div className="flex-shrink-0 align-self-center">
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                            <span className="avatar-title"> <i className="fa fa-users font-size-24"></i> </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </Link>
        </div>
    
  );
};

export default UserCounts;