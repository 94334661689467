import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { API_BASE_URL } from '../constant/apiconstant';

function Setting() {
  const [data, setData] = useState(null);
  const accountId = localStorage.getItem("accountId");
  const [id, setId] = useState(null); // State to store the _id
  const [isDataAvailable, setIsDataAvailable] = useState(false); // State to track data availability
  const [successMessage, setSuccessMessage] = useState(""); // State to manage success message

  const clearSuccessMessage = () => {
    setSuccessMessage(""); // Clear the success message
  };
  
  const showSuccessMessage = (message) => {
    setSuccessMessage(message); // Set the success message
  
    // Automatically clear the success message after 3 seconds
    setTimeout(clearSuccessMessage, 3000);
  };

  useEffect(() => {
    // Fetch data from the GET API
    fetch(
      `${API_BASE_URL}setting/getAlldataByAccountId/${accountId}`
    )
      .then((response) => response.json())
      .then((responseData) => {
        setData(responseData.query[0]); // Assuming there's only one item in the response

        setId(responseData.query[0]._id);
        setIsDataAvailable(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleToggleChange = (key) => (checked) => {
    // Update the state based on the toggle switch that was changed
    setData((prevData) => ({
      ...prevData,
      [key]: checked,
    }));
  };

  const renderToggle = (label, value, key) => {
    // Set a default value of `false` if `value` is not present or is `null`
    const defaultValue = value != null ? value : false;

    return (
      <div>
        <label>{label}</label>
        <Switch onChange={handleToggleChange(key)} checked={defaultValue} />
      </div>
    );
  };

  const postData = () => {
    // Prepare the data to be sent in the POST request
    const postData = {
      accountId: accountId,
      required_approval_tool: data.required_approval_tool,
      send_report_to_email: data.send_report_to_email,
      send_report_to_whatsapp: data.send_report_to_whatsapp,
    };

    // Make a POST request to the API
    fetch(`${API_BASE_URL}setting`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((responseData) => {
        // Handle the response data as needed
        showSuccessMessage("Setting saved successfully");
        console.log("Data successfully posted:", responseData);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  };

  const updateData = () => {
    // Prepare the data to be sent in the PUT request
    const putData = {
      required_approval_tool: data.required_approval_tool,
      send_report_to_email: data.send_report_to_email,
      send_report_to_whatsapp: data.send_report_to_whatsapp,
    };

    // Make a PUT request to the API
    fetch(`${API_BASE_URL}setting/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(putData),
    })
      .then((response) => response.json())
      .then((responseData) => {
        // Handle the response data as needed
        showSuccessMessage("Setting updated successfully");
        console.log("Data successfully updated:", responseData);
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  };

  return (
    <div>
      <Helmet>
        <title>Survey Setting | Pre-Post Survey</title>
      </Helmet>
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Survey Setting</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">
                          Survey Setting
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <br />

                      
                      <form className="form-horizontal">
                        <div className="row justify-content-center">
                          <div className="col-md-8 col-lg-6 col-xl-7">
                            <div className="col-md-12">
                            {successMessage && (
                              <div className="alert alert-success">
                                {successMessage}
                              </div>
                            )}
                              <div className="row"></div>
                              
                              {data && (
                                <div>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <label className="switch-label">
                                        Require approval tools?
                                      </label>
                                    </div>
                                    <div className="col-lg-6">
                                      <label className="switch-label">
                                        {renderToggle(
                                          "",
                                          data.required_approval_tool,
                                          "required_approval_tool"
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <label className="switch-label">
                                        Send a report on email?
                                      </label>
                                    </div>
                                    <div className="col-lg-6">
                                      <label className="switch-label">
                                        {renderToggle(
                                          "",
                                          data.send_report_to_email,
                                          "send_report_to_email"
                                        )}
                                      </label> 
                                    </div>
                                  </div>
                                  {/* <div className="row">
                                    <div className="col-lg-6">
                                      <label className="switch-label">
                                        Send a report on WhatsApp?
                                      </label>
                                    </div>
                                    <div className="col-lg-6">
                                      <label className="switch-label">
                                        {renderToggle(
                                          "",
                                          data.send_report_to_whatsapp,
                                          "send_report_to_whatsapp"
                                        )}
                                      </label>
                                    </div>
                                  </div> */}
                                </div>
                              )}
                              {!data && (
                                <div>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <label className="switch-label">
                                        Require approval tools?
                                      </label>
                                    </div>
                                    <div className="col-lg-6">
                                      <label className="switch-label">
                                        {renderToggle(
                                          "",
                                          false,
                                          "required_approval_tool"
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <label className="switch-label">
                                        Send a report on email?
                                      </label>
                                    </div>
                                    <div className="col-lg-6">
                                      <label className="switch-label">
                                        {renderToggle(
                                          "",
                                          false,
                                          "send_report_to_email"
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <label className="switch-label">
                                        Send a report on WhatsApp?
                                      </label>
                                    </div>
                                    <div className="col-lg-6">
                                      <label className="switch-label">
                                        {renderToggle(
                                          "",
                                          false,
                                          "send_report_to_whatsapp"
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <br />
                            <div className="mb-3">
                              {isDataAvailable ? (
                                <button
                                  className="btn btn-success btn-sm  waves-effect waves-light btn-rounded px-5"
                                  type="button"
                                  onClick={updateData}
                                >
                                  Update Setting
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary btn-sm  waves-effect waves-light btn-rounded px-5"
                                  type="button"
                                  onClick={postData}
                                >
                                  Save Setting
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightbar-overlay"></div>
    </div>
  );
}

export default Setting;
