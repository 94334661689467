import React, { useState, useEffect, StrictMode } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Helmet } from 'react-helmet';
import { API_BASE_URL } from '../constant/apiconstant';

const Assets = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const token = localStorage.getItem("jwt");
  //console.log("acccestoken", token);
  const bearerToken = token;
  const config = {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Type": "application/json", // Assuming your API accepts JSON data
    },
  };
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}assest/getAlldata`, config); // Replace this URL with your API endpoint
        setData(response.data);
        console.log("data", response.data);

        // Update the state with the retrieved data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData(); // Call the function to fetch data when the component mounts
  }, []);

  const staticImagePath = `${API_BASE_URL}image/`;

  const columns = [
    {
      name: "S.No",
      cell: (a, i) => i + 1,
      sortable: false,
      width: "75px"
    },
    {
      name: "Registration No.",
      selector: (row) => row.registrationNo,
      sortable: true,
      width: "150px"
    },
    {
      name: 'Customer Name',
      selector: row => row.customer[0].customername,
      sortable: true,
    },

    {
      name: "Email Id",
      selector: (row) => row.customer[0].emailId,
      sortable: true,
      width: "100px",
    },
    {
      name: "Mobile",
      selector: (row) => row.customer[0].customermobile[0] || 'N/A',
      sortable: true,
      width: "100px",
    },
    {
      name: "Address",
      selector: (row) => row.customer[0]?.customeraddress || 'N/A',
      sortable: true,
      width: "150px",
    },
    {
      name: "ZIP Code",
      selector: (row) => row.customer[0].zipcode || 'N/A',
      sortable: true,
      width: "150px",
    },
    {
      name: "Make",
      selector: (row) => row.make || 'N/A',
      sortable: true,
      width: "150px",
    },
    {
      name: "Model",
      selector: (row) => row.model || 'N/A',
      sortable: true,
      width: "150px",
    },
    {
      name: "Is Active",
      selector: (row) => row.isActive,
      sortable: true,

      cell: (row) => (<button
        className={`btn btn-sm ${row.isActive ? 'btn-outline-success' : 'btn-outline-danger'}`}
      >
        {row.isActive ? 'Active' : 'Inactive'}
      </button>),
      width: "100px",
    },
    {
      name: "Added On",
      selector: (row) => new Date(row.createDate).toLocaleDateString("en-IN", { year: 'numeric', month: 'long', day: 'numeric' }),
      sortable: true,
      width: "150px",
    },
  ];
  // Custom filter function for search
  const customFilter = (rows, searchTerm) => {
    return rows.filter(
      (row) =>
        row.registrationNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.customer[0].customername.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.customer[0].emailId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.customer[0].customermobile[0].toLowerCase().includes(searchTerm.toLowerCase())
      // Add more properties if you want to search in additional columns
    );
  };

  // Custom styles for the search box
  const searchBoxStyles = {
    container: {
      textAlign: "right",
      marginBottom: "10px", // Optional, add some bottom margin
    },
    input: {
      borderRadius: "4px",
      padding: "5px 10px",
      border: "1px solid #ccc",
      boxShadow: "none",
      float: "left", // Set the desired width of the search box
      marginBottom: "10px", // Optional, add some right margin to the search box
      marginTop: "10px",
    },
  };



  return (
    <div>
      <Helmet>
        <title>Assets List | Pre-Post Survey</title>
      </Helmet>
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Assests</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Assests</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">

                      <div className="row">
                        <div className="col-lg-4">

                        </div>
                        <div className="col-lg-6"></div>
                        <div className="col-lg-2">
                          <input
                            type="text"
                            placeholder="Search..."
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            style={searchBoxStyles.input}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="table-responsive">
                          <DataTable
                            columns={columns}
                            data={
                              searchText.trim() !== ""
                                ? customFilter(data, searchText)
                                : data
                            }
                            pagination
                            searchable
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightbar-overlay"></div>
    </div>
  );
};

export default Assets;
