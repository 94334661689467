import React, { useState, useEffect, StrictMode } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header";
import SideBarManu from "../SideBarMenu";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { API_BASE_URL } from '../../constant/apiconstant';

const AddUser = () => {
  const [selectedRole, setSelectedRole] = useState("");
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectAgency, setSelectedAgency] = useState("");
  const [error, setError] = useState(false);
  const [sperror, setspError] = useState(false);
  const navigate = useNavigate();
  const [isRoleAdmin, setIsRoleAdmin] = useState(false);
  const [isRoleSuperAdmin, setIsRoleSuperAdmin] = useState(false);
  const [isActive,setIsActive] = useState();

  const [alert, setAlert] = useState({ message: "", color: "" });
  useEffect(() => {
    if (!validatePassword(password)) {
      setspError(
        "Please enter a strong password. It should be at least 8 characters long and contain uppercase letters, lowercase letters, numbers, and special characters"
      );
      return;
    } else {
      setspError("");
    }
  });

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  function validatePassword(password) {
    // Strong password pattern: At least 8 characters, with at least one uppercase letter, one lowercase letter, one number, and one special character.
    const strongPasswordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return strongPasswordPattern.test(password);
  }

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  async function submit(e) {
    e.preventDefault();
  }

  const [data, setData] = useState([]);
  const token = localStorage.getItem("jwt");
  //console.log("acccestoken", token);
  const bearerToken = token;

  const [userData, setUserData] = useState([]);

  useEffect(() => {
    // Fetch user profile data
    axios
      .get(`${API_BASE_URL}user/profile`, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      })
      .then((response) => {
        console.log("User Profile Data:", response.data);
        setUserData(response.data);
        const decodedToken = jwt_decode(bearerToken);
        const userRole = decodedToken.role; // Assuming the role property is stored in the token's payload as "role"
        console.warn("User Rolaaaaaaaaaaaaaaaaae:", userRole);
        setIsRoleAdmin(userRole === "admin");
        setIsRoleSuperAdmin(userRole === "superadmin");
      })
      .catch((error) => console.error("Error fetching user data:", error));
  }, [bearerToken]);

  // useEffect(() => {
  //   fetchData(); // Call the fetchData function inside the useEffect to fetch the data on component mount
  // }, [bearerToken]);

  useEffect(() => {
    // Update the selected role based on the selected agency
    if (selectAgency == "Admin") {
      // If agency is "SRM Moters", set role to "Admin"
      setSelectedRole("Admin");
    } else if (selectAgency == "Agent") {
      // If agency is "Bajaj Moters", set role to "Agent"
      setSelectedRole("agent");
    }
  }, [selectAgency]);

  //     fetchData();
  // }, [bearerToken]);

  const [agentData, setAgentData] = useState([]);

  useEffect(() => {
    // Fetch agents data
    axios
      .get(
        `${API_BASE_URL}account/getAlldata?limit=100&skip=0`,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      )
      .then((response) => {
        console.log("Agents Data:", response.data);
        setAgentData(response.data);
      })
      .catch((error) => console.error("Error fetching agent data:", error));
  }, [bearerToken]);

  // const { _id } = useParams();

  async function submit(e) {
    e.preventDefault();
    if (!isNameValid) {
      setAlert({ message: "Please fill out all the required fields.", color: "danger" });
      //alert("Please fill out all the required fields.");
      return; // Do not proceed with form submission
    }

    // Create a data object with the form field values
    const userData = {
      username,
      email,
      password,
      accountId: selectAgency,
      // selectAgency:selectAgency,
      role: selectedRole, // Use the selectedRole state variable for the role field
      isActive, 
      createdBy: "user",
    };

    try {
      // Send a POST request to the server with the user data
      const response = await axios.post(
        `${API_BASE_URL}user/register`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      // Optionally, you can handle success scenarios here, like showing a success message or redirecting to a new page
      console.log("User data submitted successfully!");
      console.log("Response data:", response.data);
      setAlert({ message: "User created successfully.", color: "success" });
      window.scrollTo(0, 0);
      setTimeout(() => {
        navigate("/user");
      }, 2000);
    } catch (error) {
      setAlert({ message: "Error! Try Again.", color: "danger" });
      window.scrollTo(0, 0);
      console.error("Error submitting user data:", error);
      // Optionally, you can display an error message to the user
    }
  }

  // ADD USER
  const isNameValid = username.trim().length > 0; // Check if the name contains non-space characters

  return (
    <div>
      <div id="layout-wrapper">
      
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Add User</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Add User</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <h4 className="card-title mb-4"></h4>
                        </div>
                        <div className="col-lg-6 text-right">
                          <Link
                            to="/user"
                            className="btn btn-outline-primary btn-sm waves-effect waves-light"
                            style={{ float: "right" }}
                          >
                            <i className="fa fa-list"></i> Users
                          </Link>
                        </div>
                      </div>
                      {alert.message && (
                        <div className="row justify-content-center">
                          <div className="col-md-8 col-lg-6 col-xl-5">
                            <div
                              className={`alert alert-${alert.color} d-flex justify-content-between align-items-center`}
                            >
                              <span>{alert.message}</span>
                              <button
                                className="btn-close btn-close-white"
                                onClick={() =>
                                  setAlert({ message: "", color: "" })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <form className="form-horizontal" onSubmit={submit}>
                        <div className="row justify-content-center">
                          <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="col-md-12">
                              {isRoleSuperAdmin ? (
                                <div className="mb-3">
                                  <label For="username" className="form-label">
                                    Select Agency
                                  </label>
                                  <select
                                    name="accountId"
                                    className="form-control"
                                    value={selectAgency}
                                    onChange={(e) => {
                                      setSelectedAgency(e.target.value);
                                    }}
                                    required
                                  >
                                    <option value="">Select Agency</option>
                                    {agentData.map((item) => (
                                      <option key={item._id} value={item._id}>
                                        {item.accountName}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-12">
                              {isRoleAdmin ? (
                                <div className="mb-3">
                                  <label
                                    htmlFor="accountId"
                                    className="form-label"
                                  >
                                    Account ID (Hidden)
                                  </label>
                                  <input
                                    type="hidden"
                                    name="accountId"
                                    value={selectAgency}
                                    onChange={(e) => {
                                      setSelectedAgency(e.target.value);
                                    }}
                                    required
                                  />
                                </div>
                              ) : null}
                            </div>

                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Username
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="username"
                                  name="username"
                                  id="username"
                                  autoComplete="off"
                                  onChange={(e) => {
                                    setUserName(e.target.value);
                                  }}
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Password
                                </label>

                                <div className="input-group auth-pass-inputgroup">
                                  <input
                                    type={showPassword ? "text" : "password"}
                                    className="form-control"
                                    onChange={handlePasswordChange}
                                    placeholder="Enter password"
                                    aria-label="Password"
                                    aria-describedby="password-addon"
                                    required
                                  />
                                  <button
                                    className="btn btn-light "
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                  >
                                    {showPassword ? (
                                      <i className="mdi mdi-eye-outline"></i>
                                    ) : (
                                      <i className="mdi mdi-eye-off-outline"></i>
                                    )}
                                  </button>
                                </div>
                                {sperror && (
                                  <p style={{ color: "red" }}>{sperror}</p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Email ID
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Email ID"
                                  name="email"
                                  autoComplete="off"
                                  onChange={(e) => {
                                    setEmail(e.target.value);
                                  }}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Role
                                </label>
                                <select
                                  name="role"
                                  placeholder="Select Role"
                                  className="form-control"
                                  required
                                  value={selectedRole}
                                  onChange={(e) =>
                                    setSelectedRole(e.target.value)
                                  }
                                >
                                  <option value="">Select Role</option>
                                  
                                  {isRoleAdmin ? (
                                    <>
                                    <option value="Agent">Agent</option>
                                    </>
                                    ) : isRoleSuperAdmin ? (
                                      <>
                                      <option value={"Admin"}>Admin</option>
                                    <option value={"Agent"}>Agent</option>
                                    </>
                                    ):(
                                      <option value="Agent">Agent</option>
                                    )}
                                  
                                </select>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Status
                                </label>
                                <select
                                name="isActive"
                                className="form-control"
                                required
                                value={isActive}
                                onChange={(e) => setIsActive(e.target.value)}
                              >
                                <option value="">Status</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                              </select>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3 text-center">
                            <button
                              className="btn btn-primary waves-effect waves-light btn-rounded px-5"
                              type="submit"
                            >
                              Add User
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightbar-overlay"></div>
    </div>
  );
};

export default AddUser;
