import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RadioButton from "./RadioButton";
import { API_BASE_URL } from '../../constant/apiconstant';

const EditQuestion = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const _id = queryParams.get("_id");
  const navigate = useNavigate();

  const [question, setQuestion] = useState({});
  const [categories, setCategories] = useState([]);
  const [options, setOptions] = useState([]); // State for options
  const [answers, setAnswers] = useState([]); // State for answers
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [captureImage, setCaptureImage] = useState([]);

  const [alert, setAlert] = useState({ message: "", color: "" });
  const [questionError, setQuestionError] = useState(""); // Add question error state
  const [isCategorySelected, setIsCategorySelected] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}question/${_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        setQuestion(response.data);
        setAnswers(response.data.answer); // Set the answers state with answer data
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching question data");
        setLoading(false);
      });

    axios
      .get(`${API_BASE_URL}category`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQuestion({ ...question, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!question.question.trim()) {
      // Display an error message for the question error
      setQuestionError("Please enter a question.");
      return;
    } else {
      setQuestionError(""); // Clear the question error message if the question is not empty
    }

    if (
      question.categoryId === "" ||
      question.categoryId === "Select category"
    ) {
      // Display an error message for the category
      console.error("Please select a category.");
      setAlert({ message: "Select a valid category.", color: "danger" });
      return;
    }
    // Create an updated question object that includes the options
    const updatedQuestion = {
      ...question,
      options: options,
      answer: answers, // Include the answers
    };
  
    // Clear any previous error messages
    setAlert({ message: "", color: "" });
    axios
      .put(
        `${API_BASE_URL}question/update/${_id}`,
        updatedQuestion,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((response) => {
        console.warn("Question updated successfully:", response.data);
        navigate("/question");
      })
      .catch((error) => {
        setError("Error updating question");
        console.error("Error:", error);
        navigate("/question");
      });
  };

  // Function to add a new option
  
  // Function to add a new answer

  // Function to add a new answer
  const addAnswer = () => {
    setAnswers([...answers, { answer: "" }]);
  };

  // Function to remove an answer
  const removeAnswer = (index) => {
    const updatedAnswers = [...answers];
    updatedAnswers.splice(index, 1); // Remove the answer at the specified index
    setAnswers(updatedAnswers);
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div id="layout-wrapper">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid"></div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <h4 className="card-title mb-4">Edit Question</h4>
                      </div>
                      <div className="col-lg-6 text-right">
                        <Link
                          to="/question"
                          className="btn btn-outline-primary btn-sm waves-effect waves-light"
                          style={{ float: "right" }}
                        >
                          <i className="fa fa-question-circle"></i> Questions
                        </Link>
                      </div>
                    </div>
                  <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                      <div className="col-md-12">
                        <form
                          onSubmit={handleFormSubmit}
                          className="form-horizontal"
                        >
                          {alert.message && (
                            <div
                              className={`alert alert-${alert.color} d-flex justify-content-between align-items-center`}
                            >
                              <span>{alert.message}</span>
                              <button
                                className="btn-close btn-close-white"
                                onClick={() =>
                                  setAlert({ message: "", color: "" })
                                }
                              />
                            </div>
                          )}
                          <div className="mb-3">
                          <div className="form-group">
                            <label>Question:</label>
                            <input
                              type="text"
                              className={`form-control ${
                                questionError ? "is-invalid" : ""
                              }`}
                              name="question"
                              value={question.question}
                              onChange={handleInputChange}
                            />
                            {questionError && (
                              <div className="invalid-feedback">
                                {questionError}
                              </div>
                            )}
                          </div>
                          </div>
                          <div className="mb-3">   
                          <div className="form-group">
                            <label>Category:</label>
                            <select
                              name="categoryId"
                              className="form-control"
                              value={question.categoryId}
                              onChange={(e) => {
                                setQuestion({
                                  ...question,
                                  categoryId: e.target.value,
                                });
                                setIsCategorySelected(e.target.value !== ""); // Add this line to debug
                                console.log(
                                  "Category selected:",
                                  e.target.value
                                );
                                console.log(
                                  "isCategorySelected:",
                                  e.target.value !== ""
                                );
                              }}
                            >
                              <option value="">Select a category</option>
                              {categories.map((category) => (
                                <option key={category._id} value={category._id}>
                                  {category.categoryName}
                                </option>
                              ))}
                            </select>
                          </div>
                          </div>
                          <div className="mb-3">     
                          <div className="form-group">
                            <label>Is Capture Image:</label>
                            <div className="radio-button-group">
                              <RadioButton
                                label={
                                  <span className="radio-label">Yes</span>
                                }
                                value={true}
                                checked={question.is_capture_image === true}
                                onChange={() =>
                                  setQuestion({
                                    ...question,
                                    is_capture_image: true,
                                  })
                                }
                              />
                              <RadioButton
                                label={
                                  <span className="radio-label">No</span>
                                }
                                value={false}
                                checked={question.is_capture_image === false}
                                onChange={() =>
                                  setQuestion({
                                    ...question,
                                    is_capture_image: false,
                                  })
                                }
                              />
                            </div>
                          </div>
                          </div>    
                          <div className="mb-3">
                          {question.is_capture_image === true && (
                            <div className="form-group">
                              <label>Image Tag:</label>
                              <input
                                type="text"
                                className="form-control"
                                name="imagetag"
                                value={question.imagetag}
                                onChange={handleInputChange}
                              />
                            </div>
                          )}
                          </div> 
                          <div className="mb-3">
                          <div className="form-group">
                            <label>Question Type:</label>
                            <select
                              name="questionType"
                              className="form-control"
                              value={question.questionType}
                              onChange={handleInputChange}
                            >
                              <option value="TextBox">TextBox</option>
                              <option value="Checkbox">Checkbox</option>
                              <option value="Radio">Radio</option>
                              <option value="TextArea">TextArea</option>
                              <option value="Dropdown">Dropdown</option>
                            </select>
                          </div>
                          </div>  
                          <div className="mb-3">
                          <div className="form-group mt-3">
                            <label
                              htmlFor="answers"
                              className="form-label"
                              style={{ marginRight: "10px" }}
                            >
                              Answers:
                            </label>
                            {answers.map((answer, index) => (
                              <div key={index} className="input-group mb-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  name={`answers[${index}].answer`}
                                  value={answer.answer}
                                  onChange={(e) => {
                                    const updatedAnswers = [...answers];
                                    updatedAnswers[index].answer =
                                      e.target.value;
                                    setAnswers(updatedAnswers);
                                  }}
                                  placeholder="Answer Text"
                                />
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => removeAnswer(index)}
                                >
                                 <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                              </div>
                            ))}
                            {question.questionType !== "TextBox" &&
                            question.questionType !== "TextArea" ? (
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={addAnswer}
                              >
                              <i class="fa fa-plus" aria-hidden="true"></i>
                              </button>
                            ) : null}
                          </div>
                          </div>
                          <div className="mb-3"> 
                          <div className="form-group">
                            <label>Is Active:</label>
                            <select
                              name="isActive"
                              className="form-control"
                              value={question.isActive}
                              onChange={handleInputChange}
                            >
                              <option value="true">Active</option>
                              <option value="false">Inactive</option>
                            </select>
                          </div>
                          </div>   
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light btn-rounded px-5 mt-3"
                          >
                            Save
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditQuestion;
