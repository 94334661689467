import React from 'react';
import './NotFound.css';


const NotFound = ({ isAuthenticated }) => {
  if (!isAuthenticated) {
    return (
      <div className="full-screen-container">
        <img src="/assets/images/404.png" alt="404 Error" />
        <br />
        <h2>404 - Page Not Found</h2>
        {/* <p>The page you are looking for does not exist.</p> */}
      </div>
    );
  } else {
    // Optionally, you can render a different component or message for authenticated users
    return (
      <div className="full-screen-container" style={{backgroundColor: '#fff'}}>
        <img src="/assets/images/404.png" alt="404 Error" />
        {/* <p>The page you are looking for does not exist.</p> */}
      </div>
    );
  }
};

export default NotFound;
