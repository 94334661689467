import React, { useState } from "react";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Errorbox from "../Component/Errorbox";
import { API_BASE_URL } from '../constant/apiconstant';

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [role, setRole] = useState("");
   // const refresh = () => window.location.reload(true)
  const navigate = useNavigate();
  async function handleSubmit(e) {
    e.preventDefault();
    if (!isNameValid || !isPasswordsValid) {
      alert("Username and password are required");
      return;
    } 
    try {
      const response = await axios.post(`${API_BASE_URL}auth/login`, {
        username,
        password,
      });
    
      const accessToken = response.data.access_token;
      localStorage.setItem("jwt", accessToken);
      const decodedToken = jwtDecode(accessToken);
      console.log("Decoded Token:", decodedToken); // Log the decoded token
      const userRole = decodedToken.role;
      console.log("User Role:", userRole); // Log the user role
      setRole(userRole);
      if (userRole === "Agent") { // Replace 'restrictedRole' with the actual role name
        console.log("Access denied for this role.");
        setError(true);
        localStorage.clear();
        return;
      }
      const tokenLength = accessToken.length;
      console.log(tokenLength);
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Error logging in:", error); 
      setError(true);
      localStorage.clear()
    }
    
  }
  
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const isNameValid = username.trim().length > 0; // Check if the name contains non-space characters
  const isPasswordsValid = password.trim().length > 0; // Check if the name contains non-space characters
  return (
    
    <div className="account-pages ">
      <div className="container ">
        <div className="row vheight align-items-center justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card overflow-hidden pt-4">
              <div className="card-body pt-0">
                <div className="auth-logo text-center py-3 ">
                  <span className="login-logo">
                    <img src="assets/images/logo.png" alt="" />
                  </span>
                </div>
                <div className="p-2">
                  <h2 className=" mb-4 text-center">Login</h2>
                  {error && <Errorbox/>}
                  <form className="form-horizontal" onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="username" className="form-label">
                        Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        placeholder="Enter username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Password</label>
                      <div className="input-group auth-pass-inputgroup">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="Enter password"
                          aria-label="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          
                        />
                        <button
                          className="btn btn-light "
                          type="button"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <i className="mdi mdi-eye-outline"></i>
                          ) : (
                            <i className="mdi mdi-eye-off-outline"></i>
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                     
                    </div>
                    <div className="mt-3 d-grid">
                      {error && <p>{error}</p>}
                      <button
                        className="btn btn-primary waves-effect waves-light btn-rounded"
                        type="submit"
                      >
                        Log In
                      </button>
                    </div>
                    
                    <div className="mt-4 text-center">
                      <p>© 2023 All right reserved. </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

