import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { API_BASE_URL } from '../constant/apiconstant';

const TotalPrice = ({ role, accountId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const role = localStorage.getItem("role");

    let apiUrl = `${API_BASE_URL}survey/getAlldata?limit=1000&skip=0&preSurvey=false`;

    // Check if the user is an admin and has an accountId
    if (role === "Admin" && accountId) {
      apiUrl = `${API_BASE_URL}account/${accountId}`;
    }
    axios
      .get(apiUrl)
      .then((response) => {
        console.warn("sssssssssssssssss", response.data); // Check the response data
        setData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [role, accountId]);

  let totalSurveyPrice = 0;

  if (Array.isArray(data) && data.length > 0) {
    // Calculate the total price based on pre and post prices availability
    totalSurveyPrice = data.reduce((total, item) => {
      if (item.preSurvey.length > 0 && item.postSurvey.length > 0) {
        // Both pre and post surveys are available, add complete survey price
        return total + parseFloat(item.completeSurveyPrice);
      } else if (item.preSurvey.length > 0) {
        // Only preSurvey is available, add prePrice
        return total + parseFloat(item.prePrice);
      }
      return total;
    }, 0);
  }
  return (
    <>
      <div className="col-lg-3 col-md-6 col-sm-6">
        <Link to="/survey">
          <div className="card mini-stats-wid">
            <div className="card-body">
              <div className="d-flex">
                {Array.isArray(data) && data.length > 0 ? (
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Total Billing</p>
                    <h4 className="mb-0">{totalSurveyPrice.toFixed(2)}</h4>
                  </div>
                ) : (
                  <></>
                )}
                <div className="flex-shrink-0 align-self-center">
                  <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                    <span className="avatar-title font-size-24"> &#8377; </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};
export default TotalPrice;
