import React, { useState, useEffect, StrictMode } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Helmet } from "react-helmet";
import { API_BASE_URL } from '../constant/apiconstant';

const Profile = () => {
  const [data, setData] = useState([]);

  const [alert, setAlert] = useState({ message: "", color: "" });

  const accountId = localStorage.getItem("accountId");

  // console.warn("ASDASDASDASDASDasd", accountId)
  const staticImagePath = `${API_BASE_URL}image/`;

  const _id = accountId;
  //console.warn("@!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",_id)
  const token = localStorage.getItem("jwt");
  //console.log("acccestoken", token);

  const navigate = useNavigate();

  const bearerToken = token;
  const config = {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const [values, setValues] = useState({
    id: _id,
    accountName: "",
    address: "",
    logo : null,
  });
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}account/${_id}`)
      .then((res) => {
        // console.warn("111111111111111111s", res.data);
        setValues({
          ...values,
          accountName: res.data.accountName,
          address: res.data.address,
          logo: res.data.logo,
        });
      })
      .catch((err) => console.log(err));
  }, [_id]);

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
  
    if (selectedFile) {
      // Create a temporary URL for the selected image
      const tempImageUrl = URL.createObjectURL(selectedFile);
  
      setValues({
        ...values,
        logo: selectedFile, // Update the logo with the selected file
        tempImageUrl: tempImageUrl, // Store the temporary image URL in the state
      });
    }
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!values.accountName.trim() || !values.address.trim()) {
      setAlert({ message: "Fill all the Data.", color: "warning" });
      return;
    }

    axios
      .put(
        `${API_BASE_URL}account/update/` + _id,
        values,
        config
      )
      .then((res) => {
        if (res.status === 200) {
          setAlert({ message: "Data updated successfully.", color: "success" });
        } else {
          setAlert({ message: "Error updating data.", color: "danger" });
        }
      })
      .catch((err) => {
        setAlert({ message: "Error updating data.", color: "danger" });

        console.log(err);
      });
  };

  return (
    <div>
      <Helmet>
        <title>Agency Profile | Pre-Post Survey</title>
      </Helmet>
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Agency Profile</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">
                          Agency Profile
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <br />
                      <form className="form-horizontal">
                        <div className="row justify-content-center">
                          <div className="col-md-8 col-lg-6 col-xl-7">
                            <div className="col-md-12">
                              {alert.message && (
                                <div
                                  className={`alert alert-${alert.color} d-flex justify-content-between align-items-center`}
                                >
                                  <span>{alert.message}</span>
                                  <button
                                    className="btn-close btn-close-white"
                                    onClick={() =>
                                      setAlert({ message: "", color: "" })
                                    }
                                  />
                                </div>
                              )}
                              <div className="mb-3">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <label className="switch-label">
                                      Agency Name
                                    </label>
                                  </div>
                                  <div className="col-lg-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="username"
                                      placeholder="Enter Name"
                                      name="accountName"
                                      value={values.accountName}
                                      autoComplete="off"
                                      onChange={(e) =>
                                        setValues({
                                          ...values,
                                          accountName: e.target.value,
                                        })
                                      }
                                      style={{ textTransform: "capitalize" }}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <label className="switch-label">
                                      Agency Address
                                    </label>
                                  </div>
                                  <div className="col-lg-8">
                                    <textarea
                                      className="form-control"
                                      value={values.address}
                                      onChange={(e) =>
                                        setValues({
                                          ...values,
                                          address: e.target.value,
                                        })
                                      }
                                      id="username"
                                      placeholder="Enter Name"
                                      name="accountName"
                                      autoComplete="off"
                                      style={{
                                        textTransform: "capitalize",
                                        height: "100px",
                                      }}
                                      required
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <label className="switch-label">
                                      Agency Logo
                                    </label>
                                  </div>
                                  <div className="col-lg-8">
                                    <div className="col-md-12">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          LOGO:{" "}
                                        </label>
                                        {values.tempImageUrl ? (
                                          <img
                                            type="image"
                                            className="form-control"
                                            src={values.tempImageUrl} // Use the temporary image URL here
                                            name="logo"
                                            alt="Selected Logo"
                                          />
                                        ) : values.logo ? (
                                          <img
                                            type="image"
                                            className="form-control"
                                            src={staticImagePath + values.logo}
                                            name="logo"
                                            alt="Existing Logo"
                                          />
                                        ) : null}
                                        <input
                                          type="file"
                                          className="form-control"
                                          name="accountLogo"
                                          onChange={handleImageChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="mb-3">
                                <br />
                                <div className="mb-3">
                                  <button
                                    onClick={handleSubmit}
                                    className="btn btn-primary btn-sm  waves-effect waves-light btn-rounded px-5"
                                    type="submit"
                                  >
                                    Save Setting
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightbar-overlay"></div>
    </div>
  );
};

export default Profile;
