import React, { useState, useEffect, StrictMode } from "react";
import axios from "axios";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { API_BASE_URL } from '../../constant/apiconstant';


const EditUser = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const _id = queryParams.get("_id");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValidationMessage, setPasswordValidationMessage] =
    useState("");

    const [alert, setAlert] = useState({ message: "", color: "" });

  const [values, setValues] = useState({
    id: _id,
    username: "",
    email: "",
    password: "",
    role: "",
    isActive: "",
  });

  //console.log(_id);
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}user/${_id}`)
      .then((res) => {
        console.warn(_id);
        setValues({
          ...values,
          username: res.data.username,
          email: res.data.email,
          // password: res.data.password,
          role: res.data.role,
          isActive: res.data.isActive,
        });
      })
      .catch((err) => console.log(err));
  }, [_id]);

  const navigate = useNavigate();
  const token = localStorage.getItem("jwt");
  //console.log("acccestoken", token);
  const bearerToken = token;
  const config = {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Type": "application/json", // Assuming your API accepts JSON data
    },
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!values.username.trim() || !values.email.trim()) {
      setAlert({ message: "Please fill out all the required fields.", color: "danger" });
      return;
    }
    const updatePassword = values.password !== "";
    if (updatePassword) {
      // Password validation criteria (e.g., minimum length)
      if (values.password.length < 8) {
        setAlert({ message: "Password must be at least 8 characters long.", color: "danger" });
        return;
      }
    }

    
    // Create a payload without the password if it's not updated
    const payload = {
      id: values.id,
      username: values.username,
      email: values.email,
      role: values.role,
      isActive: values.isActive,
    };
    if (values.password.trim() !== "") {
      payload.password = values.password;

      const uppercaseRegex = /[A-Z]/;
      const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;

      if (!uppercaseRegex.test(values.password)) {
        setAlert({ message: "Password must contain at least one uppercase letter.", color: "danger" });
        //setPasswordValidationMessage("Password must contain at least one uppercase letter.");
        return;
      }

      if (!specialCharRegex.test(values.password)) {
        setAlert({ message: "Password must contain at least one special character.", color: "danger" });
        //setPasswordValidationMessage("Password must contain at least one special character.");
        return;
      }

    }
    axios
      .put(`${API_BASE_URL}user/${_id}`, payload, config)
      .then((res) => {
        setAlert({ message: "User Updated successfully.", color: "success" });
        window.scrollTo(0, 0);
        setTimeout(() => {
          navigate("/user");
        }, 2000);
      })
      .catch((err) => {
        setAlert({ message: "Error! Try Again.", color: "danger" });
        window.scrollTo(0, 0);
        console.log(err);
      });
  };

  return (
    <div>
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Edit User</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Edit User</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <h4 className="card-title mb-4"></h4>
                        </div>
                        <div className="col-lg-6 text-right">
                          <Link
                            to="/user"
                            className="btn btn-outline-primary btn-sm waves-effect waves-light"
                            style={{ float: "right" }}
                          >
                            <i className="fa fa-list"></i> User List
                          </Link>
                        </div>
                      </div>
                      {alert.message && (
                        <div className="row justify-content-center">
                          <div className="col-md-8 col-lg-6 col-xl-5">
                            <div
                              className={`alert alert-${alert.color} d-flex justify-content-between align-items-center`}
                            >
                              <span>{alert.message}</span>
                              <button
                                className="btn-close btn-close-white"
                                onClick={() =>
                                  setAlert({ message: "", color: "" })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <form className="form-horizontal" onSubmit={handleSubmit}>
                        <div className="row justify-content-center">
                          <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="col-md-12">
                              <input type="hidden" name="createdBy" />
                              <div className="mb-3">
                                <label className="form-label"> User Name</label>

                                <input
                                  type="text"
                                  name="username"
                                  className="form-control"
                                  value={values.username}
                                  autoComplete="off"
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      username: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Email
                                </label>

                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Email"
                                  name="email"
                                  autoComplete="off"
                                  value={values.email}
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      email: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Password
                                </label>

                                <div className="input-group">
                                  <input
                                    className="form-control"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Leave blank to keep current password"
                                    name="password"
                                    autoComplete="off"
                                    value={values.password}
                                    onChange={(e) =>
                                      setValues({
                                        ...values,
                                        password: e.target.value,
                                      })
                                    }
                                  />
                                  <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  >
                                    {showPassword ? (
                                      <i className="mdi mdi-eye-outline"></i>
                                    ) : (
                                      <i className="mdi mdi-eye-off-outline"></i>
                                    )}
                                  </button>
                                </div>
                                {passwordValidationMessage && ( // Display the warning if it exists
                                  <div className="text-danger">
                                    {passwordValidationMessage}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Role
                                </label>
                                <select
                                  name="IsActive"
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      role: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                  value={values.role}
                                  required
                                >
                                  <option value="Admin">Admin</option>
                                  <option value="Agent">Agent</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Status
                                </label>
                                <select
                                  name="IsActive"
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      isActive: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                  value={values.isActive}
                                  required
                                >
                                  <option value="true">Active</option>
                                  <option value="false">Inactive</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 text-center">
                            <button
                              className="btn btn-primary waves-effect waves-light btn-rounded px-5"
                              type="submit"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightbar-overlay"></div>
    </div>
  );
};

export default EditUser;