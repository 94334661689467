import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Header from "./Pages/Header";
import Dashboard from "./Pages/Dashboard";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Createaccount from "./Pages/Createaccount";
import Agencies from "./Pages/Agency/Agencies";
import AddAgencies from "./Pages/Agency/addAgency";
import Editpage from "./Pages/Agency/Editpage";
import AddUser from "./Pages/User/AddUser";
import User from "./Pages/User/User";
import EditUser from "./Pages/User/EditUser";
import SurveyCategory from "./Pages/Category/SurveyCategory";
import AddCategory from "./Pages/Category/AddCategory";
import EditCategory from "./Pages/Category/EditCategory";
import Survey from "./Pages/Survey/Survey";
import Question from "./Pages/Question/Question";
import AddQuestion from "./Pages/Question/AddQuestion";
import EditQuestion from "./Pages/Question/EditQuestion";
import Assets from "./Pages/Assets";
import Reports from "./Pages/Reports";
import UnpaidReport from "./Pages/UnpaidReports";
import NotFound from "./Component/NotFound";
import Setting from "./Pages/Setting";
import Profile from "./Pages/Profile";


function SetDocumentTitle({ title }) {
  useEffect(() => {
    document.title = title;
    return () => {
      document.title = "Pre-Post Survey"; // Reset title when component unmounts
    };
  }, [title]);
}

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("jwt");

    if (token) {
      const decodedToken = jwtDecode(token);
      setRole(decodedToken.role);
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false); // No valid token, set authentication status to false
    }
    setIsLoading(false);
  }, []); // Only run the effect once when the component mounts

  useEffect(() => {
    if (isAuthenticated) {
      // Load cache data
    }
  }, [isAuthenticated]);

  if (isLoading) {
    // Show loading spinner or message while checking authentication
    return <></>;
  }



  return (
    <div>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/unpaid-report" element={<UnpaidReport />} />
        {isAuthenticated ? (
          <Route path="/" element={<Dashboard role={role} />}>
            <Route index element={<Home />} />
            <Route
              path="agencies"
              element={
                <>
                  <Agencies />
                  <SetDocumentTitle title="Agencies" />
                </>
              }
            />
            <Route
              path="add-agency"
              element={
                <>
                  <AddAgencies />
                  <SetDocumentTitle title="Add Agency" />
                </>
              }
            />
            <Route
              path="edit-agency"
              element={
                <>
                  <Editpage />
                  <SetDocumentTitle title="Edit Agency" />
                </>
              }
            />
            <Route
              path="user"
              element={
                <>
                  <User />
                  <SetDocumentTitle title="User Page" />
                </>
              }
            />
            <Route
              path="add-user"
              element={
                <>
                  <AddUser />
                  <SetDocumentTitle title="Add User" />
                </>
              }
            />
            <Route
              path="edit-user"
              element={
                <>
                  <EditUser />
                  <SetDocumentTitle title="Edit User" />
                </>
              }
            />
             <Route
              path="setting"
              element={
                <>
                  <Setting />
                  <SetDocumentTitle title="Setting" />
                </>
              }
            />
            <Route
              path="profile"
              element={
                <>
                  <Profile />
                  <SetDocumentTitle title="Profile" />
                </>
              }
            />
            <Route
              path="survey-category"
              element={
                <>
                  <SurveyCategory />
                  <SetDocumentTitle title="Survey Category" />
                </>
              }
            />
            <Route
              path="add-category"
              element={
                <>
                  <AddCategory />
                  <SetDocumentTitle title="Add Category" />
                </>
              }
            />
            <Route
              path="edit-category"
              element={
                <>
                  <EditCategory />
                  <SetDocumentTitle title="Edit Category" />
                </>
              }
            />
            <Route
              path="survey"
              element={
                <>
                  <Survey />
                  <SetDocumentTitle title="Survey" />
                </>
              }
            />
            <Route
              path="question"
              element={
                <>
                  <Question />
                  <SetDocumentTitle title="Question" />
                </>
              }
            />
            <Route
              path="add-question"
              element={
                <>
                  <AddQuestion />
                  <SetDocumentTitle title="Add Question" />
                </>
              }
            />
            <Route
              path="edit-question"
              element={
                <>
                  <EditQuestion />
                  <SetDocumentTitle title="Edit Question" />
                </>
              }
            />
            <Route
              path="asset"
              element={
                <>
                  <Assets />
                  <SetDocumentTitle title="Assets" />
                </>
              }
            />
          </Route>
        ) : (
          <Route path="/" element={<Navigate to="/login" />} />
        )}

        <Route
          path="*"
          element={<NotFound isAuthenticated={isAuthenticated} />}
        />
      </Routes>
    </div>
  );
};

export default App;

