import React, { useState, useEffect } from "react";
import { StrictMode } from "react";
import { useNavigate, Link } from "react-router-dom";
import Header from "../../Pages/Header";
import SideBarManu from "../../Pages/SideBarMenu";
import axios from "axios";
import NumberInput from "../../Component/NumberInput";
import { API_BASE_URL } from '../../constant/apiconstant';



const AddAgencies = () => {
  const history = useNavigate();

  const [accountName, setAccountName] = useState("");
  const [address, setAddress] = useState("");
  const [logo, setLogo] = useState(null);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState("");
  const [prePrice, setPrePrice] = useState(0);
  const [postPrice, setPostPrice] = useState("");
  const [completeSurveyPrice, setCompleteSurveyPrice] = useState("");

  const [alert, setAlert] = useState({ message: "", color: "" });

  async function submit(e) {
    e.preventDefault(history);

    if (!isNameValid || !isAddressValid) {
      setAlert({
        message: "Please fill out all the required fields.",
        color: "danger",
      });
      //alert("Please fill out all the required fields.");
      return; // Do not proceed with form submission
    }
    try {
      const token = localStorage.getItem("jwt");
      console.log("acccestoken", token);

      const formData = new FormData();
      formData.append("logo", logo);
      formData.append("accountName", accountName);
      formData.append("address", address);
      formData.append("createdBy", "User");
      formData.append("modifiedBy", "User");
      formData.append("isActive", isActive);
      formData.append("prePrice", prePrice);
      formData.append("postPrice", postPrice);
      formData.append("completeSurveyPrice", completeSurveyPrice);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .post(`${API_BASE_URL}account/create`, formData, config)

        .then((res) => {
          setAlert({
            message: "Agency Created successfully.",
            color: "success",
          });
          window.scrollTo(0, 0);
          setTimeout(() => {
            navigate("/agencies");
          }, 2000);
          console.log(res.data);
          //navigate("/agencies");
        })
        .catch((e) => {
          setAlert({ message: "Error! Try Again.", color: "danger" });
          window.scrollTo(0, 0);
          //alert("wrong details");
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  }

  const isNameValid = accountName.trim().length > 0; // Check if the name contains non-space characters
  const isAddressValid = address.trim().length > 0; // Check if the name contains non-space characters
  useEffect(() => {
    setPostPrice(completeSurveyPrice);
  }, [completeSurveyPrice]);


  return (
    <div>
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Add Agency</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Add Agency</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <h4 className="card-title mb-4"></h4>
                        </div>
                        <div className="col-lg-6 text-right">
                          <Link
                            to="/agencies"
                            className="btn btn-outline-primary btn-sm waves-effect waves-light"
                            style={{ float: "right" }}
                          >
                            <i className="fa fa-list"></i> Agency List
                          </Link>
                        </div>
                      </div>
                      {alert.message && (
                        <div className="row justify-content-center">
                          <div className="col-md-8 col-lg-6 col-xl-5">
                            <div
                              className={`alert alert-${alert.color} d-flex justify-content-between align-items-center`}
                            >
                              <span>{alert.message}</span>
                              <button
                                className="btn-close btn-close-white"
                                onClick={() =>
                                  setAlert({ message: "", color: "" })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <form className="form-horizontal" onSubmit={submit}>
                        <div className="row justify-content-center">
                          <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="col-md-12">
                              <input type="hidden" name="createdBy" />
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Agency Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="username"
                                  placeholder="Enter Name"
                                  name="accountName"
                                  autoComplete="off"
                                  style={{ textTransform: "capitalize" }}
                                  onChange={(e) =>
                                    setAccountName(e.target.value)
                                  }
                                  required
                                />
                              </div>

                              <div className="mb-3">
                                <input
                                  type="hidden" // Change input type to hidden
                                  name="prePrice"
                                  value={prePrice} // Set initial value to 0
                                  readOnly
                                />
                              </div>
                              <div className="mb-3">
                                <input
                                type="hidden" // Change input type to hidden

                                  placeholder="PostPrice"
                                  value={postPrice}
                                  onChange={setPostPrice}
                                />
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Complete Survey Price
                                </label>

                                <NumberInput
                                  placeholder="Complete Survey Price"
                                  value={completeSurveyPrice}
                                  onChange={setCompleteSurveyPrice}
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Address
                                </label>
                                <textarea
                                  className="form-control"
                                  placeholder="Address"
                                  name="Address"
                                  onChange={(e) => setAddress(e.target.value)}
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Logo
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  name="accountNLogoame"
                                  onChange={(e) => setLogo(e.target.files[0])}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Status
                                </label>
                                <select
                                  name="IsActive"
                                  className="form-control"
                                  onChange={(e) => setIsActive(e.target.value)}
                                  required
                                >
                                  <option value="">Select</option>
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 text-center">
                            <button
                              className="btn btn-primary waves-effect waves-light btn-rounded px-5"
                              type="submit"
                            >
                              Add Agency
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightbar-overlay"></div>
    </div>
  );
};

export default AddAgencies;
