import React, { useState, useEffect } from "react";
import { StrictMode } from "react";
import { useNavigate, Link, useHistory } from "react-router-dom";
import Header from "../Header";
import SideBarManu from "../SideBarMenu";
import axios from "axios";
import { API_BASE_URL } from '../../constant/apiconstant';

const AddCategory = () => {
  const token = localStorage.getItem("jwt");
  const accountId = localStorage.getItem("accountId");
  console.log("accountId", accountId);
  console.log("accessToken", token);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    categoryName: '',
    isActive: '',
    createdBy: 'Admin', 
    accountId: accountId, // Use accountId directly
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [validationErrors, setValidationErrors] = useState({
    categoryName: false,
    isActive: false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();


    const errors = {
      categoryName: formData.categoryName.trim() === '',
      isActive: formData.isActive.trim() === '',
    };

    if (errors.categoryName || errors.isActive) {
      setValidationErrors(errors);
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}category/createdata`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
       
      // Handle response if needed
      console.log("Response:", response.data);
      navigate('/survey-category');
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return (
    <div>
      <div id="layout-wrapper">
        
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Add Category</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Add Category</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <h4 className="card-title mb-4"></h4>
                        </div>
                        <div className="col-lg-6 text-right">
                          <Link
                            to="/survey-category"
                            className="btn btn-outline-primary btn-sm waves-effect waves-light"
                            style={{ float: "right" }}
                          >
                            <i className="fa fa-list"></i> Survey Category List
                          </Link>
                        </div>
                      </div>

                      <form className="form-horizontal" onSubmit={handleSubmit}>
                        <div className="row justify-content-center">
                          <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label htmlFor="name" className="form-label">
                                  Category Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="categoryName"
                                  placeholder="Category Name"
                                  name="categoryName"
                                  value={formData.categoryName}
                                  onChange={handleChange}
                                  required
                                />
                                {validationErrors.categoryName && (
                                  <p style={{ color: 'red' }}>Category name is required</p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Status
                                </label>

                                <select
                                  name="isActive"
                                  className="form-control"
                                  value={formData.isActive}
                                  onChange={handleChange}
                                >
                                  <option value="">Status</option>
                                  <option value={true}>Active</option>
                                  <option value={false}>InActive</option>
                                </select>
                                {validationErrors.isActive && (
                                  <p style={{ color: 'red' }}>Status is required</p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 text-center">
                            <button
                              className="btn btn-primary waves-effect waves-light btn-rounded px-5"
                              type="submit"
                            >
                              Add Category
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightbar-overlay"></div>
    </div>
  );
};

export default AddCategory;
