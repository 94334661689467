import React from "react";
import { Outlet } from "react-router-dom";
import SideBarMenu from "./SideBarMenu";
import Header from "./Header";

const Dashboard = ({role }) => {
  return (
    <>
      <Header />
      <SideBarMenu />
      <Outlet /> {/* This will render the nested routes */}
    </>
  );
};

export default Dashboard;
