import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useNavigate,useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Errorbox from "../Component/Errorbox";
import { Helmet } from 'react-helmet';
import html2pdf from 'html2pdf.js';
import { API_BASE_URL } from '../constant/apiconstant';
const Reports = () => { 
   
  if (window.location.pathname === '/reports') {
    import('../styles/reports.css');
  }

  const [surveyData, setSurveyData] = useState(null);
  const [surveyStatus, setSurveyStatus] = useState('');
  const [surveyDate, setSurveyDate] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const surveyId = queryParams.get('surveyId');

  const [preImageUrls, setPreImageUrls] = useState([]);
  const [postImageUrls, setPostImageUrls] = useState([]);
  const [logoImageUrl, setLogoImageUrl] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [accountData, setAccountData] = useState(null);
  
  useEffect(() => {
    const fetchSurveyData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}survey/${surveyId}`
        );
        setSurveyData(response.data);
        if (response.data.dataId && response.data.dataId.accountId) {
          setAccountId(response.data.dataId.accountId);
        }
        if(response.data.dataId.postSurvey.length == 0){
			    setSurveyDate(response.data.dataId.preSurveyCreatedDate);
            setSurveyStatus("Pre Survey");
        } else if(response.data.dataId.postSurvey && response.data.dataId.postSurvey.length > 0) {
          const lastElement = response.data.dataId.postSurvey[response.data.dataId.postSurvey.length - 1];
		      	setSurveyDate(lastElement.postSurveyCreatedDate);
            setSurveyStatus("Complete Survey");
        }

       // console.log(surveyStatus);

      } catch (error) {
        console.error('Error fetching survey data:', error);
      }
    };

    fetchSurveyData();
  }, [surveyId]);


  const fetchAccountData = async () => {
    try {
      const accountResponse = await fetch(`${API_BASE_URL}account/${accountId}`);
      const accountData = await accountResponse.json();
      if (Object.keys(accountData).length > 0) {
        setAccountData(accountData);
      } else {
        console.warn('Fetched account data is empty.');
      }
    } catch (error) {
      console.error('Error fetching account data:', error);
    }
  };

  useEffect(() => {
    if (accountId) {
      fetchAccountData();
    }
  }, [accountId]);


  const baseImageUrl = `${API_BASE_URL}survey-image/`;

  
  
  const convertToPdf = () => {
    const element = document.getElementById('content-to-convert'); // Replace with the ID of the HTML element you want to convert
    
    const opt = {
      margin: 8,
      filename: `${surveyId}_survey.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
    
    html2pdf().from(element).set(opt).save();
  };  

  


  const getBase64Image = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  useEffect(() => {

    if (accountData && accountData.logo) {
      const logoImageUrl = `${probaseImageUrl}${accountData.logo}`;
      getBase64Image(logoImageUrl)
        .then(base64Logo => {
          if (base64Logo) {
            setLogoImageUrl(base64Logo);
          }
        });
    }


  }, [accountData]);

  useEffect(() => {
    const fetchImages = async () => {
      if (surveyData && surveyData.dataId && surveyData.dataId.preSurvey) {
        const preImages = await Promise.all(
          surveyData.dataId.preSurvey.map(async (preQuestion) => {
            if (preQuestion.imagepath) {
              return getBase64Image(`${baseImageUrl}${preQuestion.imagepath}`);
            } else {
              return null;
            }
          })
        );
        setPreImageUrls(preImages);
      }

      if (surveyData && surveyData.dataId && surveyData.dataId.postSurvey) {

        let lastArray;
    
        if (surveyData.dataId.isFixed) {
          lastArray = surveyData.dataId.postSurvey[surveyData.dataId.postSurvey.length - 1];
        } else {
          lastArray = [];
        }


        if (lastArray && Array.isArray(lastArray.Survey)) {
          const postImages = await Promise.all(
            lastArray.Survey.map(async (postQuestion) => {
              if (postQuestion.imagepath) {
                return getBase64Image(`${baseImageUrl}${postQuestion.imagepath}`);
              } else {
                return null;
              }
            })
          );
          setPostImageUrls(postImages); 
        } else {
          console.error('Invalid data structure in surveyData.');
        }
      }
    };

    if (surveyData) {
      fetchImages();
    }
  }, [baseImageUrl, surveyData]);

  

  const probaseImageUrl = `${API_BASE_URL}image/`;
  
    function breakString(inputString, segmentLength) {
	  const segments = [];
    if(inputString){
      for (let i = 0; i < inputString.length; i += segmentLength) {
      segments.push(inputString.substring(i, i + segmentLength));
      }
    }
	  return segments.join('\n');
	}


  return (
    
    <div className="account_pages">    
		  <Helmet>
        <title>{surveyStatus} Report | Pre-Post Survey</title>
      </Helmet>  
      {surveyData && (
        <>
        <table style={{'width': '100%', 'border': '0%' }} id="content-to-convert">
            <tr>
              <td valign='top' style={{'width': '55%',paddingTop: '20px', paddingBottom:'20px'}}>
                   {logoImageUrl && (
                      <img alt="logo" src={logoImageUrl} className='logo' />
                    )}
              </td>
              <td valign='top' style={{'width': '45%',paddingTop: '20px', paddingBottom:'20px' }}>
                {accountData && (
                  <div>
                      {accountData.address && accountData.address.split('\r\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                  </div>
                )}  
              </td>
            </tr>
            <tr>
              <td valign='top' style={{paddingTop: '20px', paddingBottom:'20px' }}>
                <p><b>Customer Details</b><br />
                  <b>Registration No.:</b> {surveyData.dataId.registrationNo}<br />
                  <b>Customer Name:</b> {surveyData.dataId.customername}<br />
                  <b>Mobile No.:</b> {surveyData.dataId.customermobile}<br />
                  <b>Email ID:</b> {surveyData.dataId.emailId}</p>
              </td>
              <td valign='top' style={{paddingTop: '20px', paddingBottom:'20px' }}>
              <table className='meta' style={{'width': '100%' }}>
                  <tr>
                    <th><span><b>Survey Date:</b></span></th>
                    <td><span>{new Date(
                      new Date(surveyDate).getTime() - 6 * 60 * 60 * 1000 + 30 * 60 * 1000
                    ).toLocaleString("en-IN", {
                      timeZone: "Asia/Kolkata",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    })}</span></td>
                  </tr>
                  <tr>
                    <th><span><b>Survey Status</b></span></th>
                    <td><span><b>{surveyStatus}</b></span></td>
                  </tr>
                  <tr>
                    <th><span><b>Download Report</b></span></th>
                    <td><button id="pdfButton" onClick={convertToPdf} className="btn btn-sm btn-outline-danger"><i className="fa fa-file-pdf" aria-hidden="true"></i> Download PDF</button></td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
              <table className="inventory">
                  <tr style={{ backgroundColor: '#d3d6f1', color: '#000', fontWeight: 'bold', 'fontSize': '16px' }}>
                    <td>Questions</td>
                    <td>Pre Photo</td>
                    <td>Post Photo</td>
                  </tr>
                  {surveyData.dataId.preSurvey.map((preQuestion,index) => {
                    let lastArray;
                    if(surveyData.dataId.isFixed){
                      lastArray = surveyData.dataId.postSurvey[surveyData.dataId.postSurvey.length - 1];
                    } else {
                      lastArray = []; 
                    }

                    const matchingPostQuestion = lastArray.Survey && lastArray.Survey.length > 0
                    ? lastArray.Survey.find(
                        postQuestion => postQuestion.question === preQuestion.question
                      )
                    : {};
                    

                    return (
                      <tr key={preQuestion._id} style={{pageBreakInside:'avoid',paddingTop: "20px", paddingBottom: "20px" }}>
                        <td>
                          <p><b>Q {index + 1}: {preQuestion.question}</b></p>
                          <br/>
                          <p><b>Pre A:</b> {breakString(preQuestion.Answer,20)}</p>
                          <p><b>Pre Comment:</b> {breakString(preQuestion.answerdescription,20)}</p>
                          <br/>
                          {matchingPostQuestion && (
                            <div>
                              <p><b>Post A:</b> {breakString(matchingPostQuestion.Answer,20)}</p>
                              <p><b>Post Comment:</b> {breakString(matchingPostQuestion.answerdescription,20)}</p>
                            </div>
                          )}
                        </td>
                        <td>
                          <span>
                           {preImageUrls[index] ? (
                              <div className="zoom-container">
                                <img src={preImageUrls[index]} alt="Pre Image" style={{marginTop:"50px"}}/>
                              </div>
                            ) : (preQuestion.is_capture_image === true)?(
                              <p style={{height: '260px',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>Loading image....</p>
                            ):(<div style={{height: "260px",display: "flex",justifyContent: "center",alignItems: "center"}}>An image is not required in this section.</div>)}
                          </span>
                        </td>
                        <td>
                          <span>
                          {matchingPostQuestion.is_capture_image === true && matchingPostQuestion.imagepath && postImageUrls[index] ? (
                              
                                <div className="zoom-container">
                                  <img src={postImageUrls[index]} alt="Post Image" style={{marginTop:"50px"}}/>
                              </div>
                            ) :(matchingPostQuestion.is_capture_image === true)?(<p style={{height: '260px',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>Loading image....</p>): (
                              <div>
                                {preQuestion.is_capture_image === false ? (
                                  <div style={{height: "260px",display: "flex",justifyContent: "center",alignItems: "center"}}>An image is not required in this section.</div>
                                ) : (
                                  <div className="zoom-container"><img src="assets/images/post-placeholder.jpg" alt="Placeholder" style={{marginTop:"50px"}} /></div>
                                )}
                              </div>
                            )}
                          </span>
                        </td>
                      </tr>
                    );
                  })}



                  
                </table>
              </td>
            </tr>
          </table>
            </>
        )}
        {!surveyData && <p>Loading survey data...</p>}
      </div>
  );
};

export default Reports;

