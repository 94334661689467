import React, { useEffect, useState } from "react";
import axios from "axios";
import AccountTotalData from "./AccountTotalData";
import SurveyCount from "./SurveyCount";
import UserCounts from "./UserCounts";
import jwtDecode from "jwt-decode";
import DataTable from "react-data-table-component";
import TotalPrice from "./TotalPricr";
import "../styles/Custom.css";
import { API_BASE_URL } from '../constant/apiconstant';


function SuperadminList() {
  const token = localStorage.getItem("jwt");
  const decodedToken = jwtDecode(token);
  const role = decodedToken.role;
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  let totalSurveyPrice = 0;
  useEffect(() => {
    axios
      .get(
        `${API_BASE_URL}survey/getAlldata?limit=1000&skip=0&preSurvey=false`
      )
      .then((response) => {
        console.warn("sssssssssssssssss", response.data); // Check the response data
        setData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);
  const columns = [
    {
      name: "S No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "75px",
    },
    {
      name: "Registration No.",
      selector: "registrationNo",
      sortable: true,
    },
    {
      name: "Email Id",
      selector: "emailId",
      sortable: true,
    },
    {
      name: "AgencyName",
      selector: "accountName",
      sortable: true,
    },
    {
      name: "Survey Status",
      cell: (row) => {
        const isPreSurveyComplete = row.preSurvey.length > 0;
        const isPostSurveyComplete = row.isFixed;
        let surveyStatus = '';
        let buttonClass = '';

        if (isPreSurveyComplete && isPostSurveyComplete) {
          surveyStatus = 'Complete Survey';
          buttonClass = 'btn-outline-success';
        } else if (isPreSurveyComplete) {
          buttonClass = 'btn-outline-warning';
          surveyStatus = 'Pre survey';
        }

        return (
          <span className={`btn btn-sm ${buttonClass}`}>
            {surveyStatus}
          </span>
        );
      },
      width: "150px",
    },
    {
      name: "Survey Price",
      cell: (row) => {
        const isPreSurveyComplete = row.preSurvey.length > 0;
        const isPostSurveyComplete = row.postSurvey.length > 0;
        let priceText = "N/A";

        if (isPreSurveyComplete && isPostSurveyComplete) {
          priceText = (
            parseFloat(row.prePrice) + parseFloat(row.postPrice)
          );
        } else if (isPreSurveyComplete) {
          priceText = parseFloat(row.prePrice);
        } else if (isPostSurveyComplete) {
          priceText = parseFloat(row.postPrice);
        }

        return priceText;
      },
      sortable: true,
    },
    {
      name: "Survey Report",
      cell: (row) => (
        <a
          href={`/reports?surveyId=${row._id}`}
          className="btn btn-outline-primary btn-sm waves-effect waves-light"
          title="View Reports"
          target="_blank"
        >
          <i className="fas fa-poll"></i>
        </a>
      ),
    },
  ];
  const customFilter = (rows, searchTerm) => {
    return rows.filter(
      (row) =>
        row.registrationNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.emailId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.accountName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.surveyType.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
  return (
    <div id="layout-wrapper">
        <div className="row">
          {role === "superadmin" && <AccountTotalData />}
          <SurveyCount />
          <UserCounts />
          <TotalPrice/>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title mb-4">Recent Survey List</h4>
                <DataTable
                  columns={columns}
                  data={
                    searchText.trim() !== ""
                      ? customFilter(data, searchText)
                      : data
                  }
                  pagination
                  searchable
                />
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}
export default SuperadminList;
