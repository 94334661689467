import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from '../constant/apiconstant';

const SurveyCount = () => {
    
    const navigate = useNavigate();
    const token = localStorage.getItem("jwt");
    console.log(token);
    if (!token) {
      localStorage.clear();
      navigate("/login");
    }
    // Decode the token to access its payload
    const decodedToken = jwtDecode(token);
    console.log(decodedToken);
    // Access data from the decoded token
    const accountId = decodedToken.accountId ? decodedToken.accountId : null;
    const username = decodedToken.username;
    const role = decodedToken.role;
    localStorage.setItem("accountId", accountId);
    localStorage.setItem("username", username);
    localStorage.setItem("role", role);

    const [totalCount, setTotalCount] = useState(0);
    const [totalSurveyPrice, setTotalSurveyPrice] = useState(0); // Initialize totalSurveyPrice state

    const accountIdToFilter = accountId;

    useEffect(() => {
      async function fetchData() {
        try {

          const response = await axios.get(`${API_BASE_URL}survey/getAlldata?limit=100&skip=0`);
          const data = response.data.data;
          //console.warn("pppppppppppppppppppppppppp", data)
          // Filter the data based on the provided accountId
          const filteredData = accountIdToFilter
            ? data.filter(item => item.accountId === accountIdToFilter)
            : data;
          //console.warn("ggggggggggggggggggggggggg",filteredData)
          const count = filteredData.length;
          setTotalCount(count);
          let total = 0; // Initialize totalSurveyPrice variable
          
          // Calculate the sum of survey prices
          filteredData.forEach(item => {
            const preSurveyLength = item.preSurvey.length;
            const postSurveyLength = item.postSurvey.length;

            if (preSurveyLength > 0 && postSurveyLength > 0) {
              // Both Pre-survey and Post-survey are available
              total += parseFloat(item.completeSurveyPrice);
            } else if (preSurveyLength > 0 && postSurveyLength === 0) {
              // Only Pre-survey is available
              total += parseFloat(item.prePrice);
            }
          });
          

          setTotalSurveyPrice(total.toFixed(2)); // Update totalSurveyPrice state
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }

      fetchData();
    }, [accountIdToFilter]);

    return (
      <>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <Link to="/survey">
            <div className="card mini-stats-wid">
              <div className="card-body">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Total Survey</p>
                    <h4 className="mb-0">{totalCount}</h4>
                  </div>
                  <div className="flex-shrink-0 align-self-center">
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                      <span className="avatar-title"> <i className="fa fa-building font-size-24"></i> </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        {role === "Admin" && (
          <div className="col-lg-3 col-md-6 col-sm-6">
            <Link to="/survey">
              <div className="card mini-stats-wid">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">Total Survey Price</p>
                      <h4 className="mb-0">{totalSurveyPrice}</h4>
                    </div>
                    <div className="flex-shrink-0 align-self-center">
                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                        <span className="avatar-title font-size-24"> &#8377; </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        )}
      </>
    );
};

export default SurveyCount;
