import React, { useState, useEffect, StrictMode } from "react";
import axios from "axios";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Header from "../Header";
import SideBarManu from "../SideBarMenu";
import { API_BASE_URL } from '../../constant/apiconstant';

const EditCategory = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const _id = queryParams.get("_id");

  const token = localStorage.getItem("jwt");
  console.log("acccestoken", token);
  const bearerToken = token;
  const config = {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Type": "application/json", // Assuming your API accepts JSON data
    },
  };

  const [values, setValues] = useState({
    id: _id,
    categoryName: "",
    prePrice: "",
    postPrice: "",
    completeSurveyPrice: "",
    role: "",
    isActive: "",
  });

  console.log(_id);
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}category/${_id}`, config)
      .then((res) => {
        console.warn(_id);
        setValues({
          ...values,
          categoryName: res.data.categoryName,
          prePrice: res.data.prePrice,
          postPrice: res.data.postPrice,
          completeSurveyPrice: res.data.completeSurveyPrice,
          isActive: res.data.isActive,
        });
      })
      .catch((err) => console.log(err));
  }, [_id]);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!values.categoryName.trim()) {
      alert("Please fill out all the required fields.");
      return;
    }

    axios
      .put(
        `${API_BASE_URL}category/update/${_id}`,
        values,
        config
      )
      .then((res) => {
        navigate("/survey-category");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div id="layout-wrapper">
       
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Edit Category</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">
                          Edit Category
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <h4 className="card-title mb-4"></h4>
                        </div>
                        <div className="col-lg-6 text-right">
                          <Link
                            to="/survey-category"
                            className="btn btn-outline-primary btn-sm waves-effect waves-light"
                            style={{ float: "right" }}
                          >
                            <i className="fa fa-list"></i> Survey Category List
                          </Link>
                        </div>
                      </div>

                      <form className="form-horizontal" onSubmit={handleSubmit}>
                        <div className="row justify-content-center">
                          <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="col-md-12">
                              <input type="hidden" name="createdBy" />
                              <div className="mb-3">
                                <label className="form-label">
                                  {" "}
                                  Category Name
                                </label>

                                <input
                                  type="text"
                                  name="categoryName"
                                  className="form-control"
                                  value={values.categoryName}
                                  autoComplete="off"
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      categoryName: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            

                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Status
                                </label>
                                <select
                                  name="IsActive"
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      isActive: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                  value={values.isActive}
                                  required
                                >
                                  <option value="true">Active</option>
                                  <option value="false">Inactive</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 text-center">
                            <button
                              className="btn btn-primary waves-effect waves-light btn-rounded px-5"
                              type="submit"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightbar-overlay"></div>
    </div>
  );
};

export default EditCategory;
