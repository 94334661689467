import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";


// import jwtDecode from "jwt-decode"; // Import jwtDecode


const SideBarMenu = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("jwt");

  if (!token) {
    localStorage.clear();
    navigate("/login");
  }
  const decodedToken = jwtDecode(token);
  console.log(decodedToken);
  const role = decodedToken.role;
  localStorage.setItem("role", role);


  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };
  
  //console.log(location.pathname);


  return (
    <div>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
            <li className={` ${isActive("/") ? "mm-active" : ""}`}>
                  <Link to="/" className={` ${isActive("/") ? "active waves-effect" : "waves-effect"}`}>
                    {" "}
                    <i className="bx bxs-dashboard"></i>{" "}
                     <span key="t-chat">Dashboard</span>{" "}
                  </Link>
                </li>
              {/* Always show this menu item */}
              <li className={` ${isActive("/user") ? "mm-active" : ""}`}>
                <Link to="user" className={` ${isActive("/user") ? "active waves-effect" : "waves-effect"}`}>
                  <i className="fa fa-users"></i>{" "}
                  <span key="t-chat">Users</span>{" "}
                </Link>
              </li>
              <li className={` ${isActive("/survey") ? "mm-active" : ""}`}>
              <Link to="survey" className={` ${isActive("/survey") ? "active waves-effect" : "waves-effect"}`}>
                <i className="fas fa-poll"></i> {" "}
                <span key="t-chat">Survey List</span>{" "}
              </Link>
            </li>
            
            
             {/* Show these menu items for superadmin */}
             {role === "Admin" && (
               <>
               <li className={` ${isActive("/survey-category") ? "mm-active" : ""}`}>
                  <Link to="survey-category" className={` ${isActive("/survey-category") ? "active waves-effect" : "waves-effect"}`}>
                    <i className="fa fa-list-alt"></i> {" "}
                    <span key="t-chat">Survey Category</span>{" "}
                  </Link>
                </li>
                <li className={` ${isActive("/question") ? "mm-active" : ""}`}>
                  <Link to="question" className={` ${isActive("/question") ? "active waves-effect" : "waves-effect"}`}>
                    <i className="fa fa-question-circle"></i> {" "}
                    <span key="t-chat">Question Template</span>{" "}
                  </Link>
                </li>
                
               </>
             )}
              {/* Show these menu items for superadmin */}
              {role === "superadmin" && (
                <>
                  <li className={` ${isActive("/agencies") ? "mm-active" : ""}`}>
                    <Link to="agencies" className={` ${isActive("/agencies") ? "active waves-effect" : "waves-effect"}`}>
                      <i className="fa fa-building"></i> {" "}
                      <span key="t-chat">Agencies</span>{" "}
                    </Link>
                  </li>
                  <li className={` ${isActive("/asset") ? "mm-active" : ""}`}>
                    <Link to="asset" className={` ${isActive("/asset") ? "active waves-effect" : "waves-effect"}`}>
                      <i className="fas fa-poll"></i> {" "}
                      <span key="t-chat">Assets</span>{" "}
                    </Link>
                  </li>
                  <li className={` ${isActive("/question") ? "mm-active" : ""}`}>
                  <Link to="question" className={` ${isActive("/question") ? "active waves-effect" : "waves-effect"}`}>
                    <i className="fa fa-question-circle"></i> {" "}
                    <span key="t-chat">Question Template</span>{" "}
                  </Link>
                </li>
                
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBarMenu;
