import React, { useState, useEffect, StrictMode } from "react";
import axios from "axios";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Header from "../../Pages/Header";
import SideBarManu from "../../Pages/SideBarMenu";
import { Helmet } from "react-helmet";
import { API_BASE_URL } from '../../constant/apiconstant';


const EditForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const _id = queryParams.get("_id");

  const [alert, setAlert] = useState({ message: "", color: "" });

  const [values, setValues] = useState({
    id: _id,
    accountName: "",
    address: "",
    modifiedBy: "User",
    createDate: "",
    logo: null,
    isActive: "",
    createdBy: "user",
    prePrice: 0,
    postPrice: "",
    completeSurveyPrice: "",
  });
  const staticImagePath = `${API_BASE_URL}image/`;
  console.log(_id);
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}account/${_id}`)
      .then((res) => {
        console.warn(_id);
        setValues({
          ...values,
          accountName: res.data.accountName,
          address: res.data.address,
          modifiedBy: res.data.modifiedBy,
          createDate: res.data.createDate,
          logo: res.data.logo,
          isActive: res.data.isActive,
          prePrice: res.data.prePrice,
          postPrice: res.data.postPrice,
          completeSurveyPrice: res.data.completeSurveyPrice,
        });
      })
      .catch((err) => console.log(err));
  }, [_id]);

  const navigate = useNavigate();
  const token = localStorage.getItem("jwt");
  //console.log("acccestoken", token);
  const bearerToken = token;
  const config = {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      "content-type": "multipart/form-data",
      Accept: "*/*",
    },
  };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      // Create a temporary URL for the selected image
      const tempImageUrl = URL.createObjectURL(selectedFile);

      setValues({
        ...values,
        logo: selectedFile,
        tempImageUrl: tempImageUrl, // Store the temporary image URL in the state
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !values.accountName.trim() ||
      !values.address.trim() ||
      !values.modifiedBy.trim()
    ) {
      alert("Please fill out all the required fields.");
      return;
    }

    axios
      .put(
        `${API_BASE_URL}account/update/` + _id,
        values,
        config
      )
      .then((res) => {
        setAlert({ message: "Agency Updated successfully.", color: "success" });
        window.scrollTo(0, 0);
        setTimeout(() => {
          navigate("/agencies");
        }, 2000);
        //navigate("/agencies");
        console.warn(res);
      })
      .catch((err) => {
        setAlert({ message: "Error! Try Again.", color: "danger" });
        window.scrollTo(0, 0);
        console.log(err);
      });
  };

  return (
    <div>
      <Helmet>
        <title>Edit Agency | Pre-Post Survey</title>
      </Helmet>
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Edit Agency</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Edit Agency</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <h4 className="card-title mb-4">Edit Agency</h4>
                        </div>
                        <div className="col-lg-6 text-right">
                          <Link
                            to="/agencies"
                            className="btn btn-outline-primary btn-sm waves-effect waves-light"
                            style={{ float: "right" }}
                          >
                            <i className="fa fa-list"></i> Agency List
                          </Link>
                        </div>
                      </div>
                      {alert.message && (
                        <div className="row justify-content-center">
                          <div className="col-md-8 col-lg-6 col-xl-5">
                            <div
                              className={`alert alert-${alert.color} d-flex justify-content-between align-items-center`}
                            >
                              <span>{alert.message}</span>
                              <button
                                className="btn-close btn-close-white"
                                onClick={() =>
                                  setAlert({ message: "", color: "" })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <form className="form-horizontal" onSubmit={handleSubmit}>
                        <div className="row justify-content-center">
                          <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="col-md-12">
                              <input type="hidden" name="createdBy" />
                              <div className="mb-3">
                                <label className="form-label">
                                  {" "}
                                  Agency Name
                                </label>

                                <input
                                  type="text"
                                  name="Account Name"
                                  className="form-control"
                                  style={{ textTransform: "capitalize" }}
                                  value={values.accountName}
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      accountName: e.target.value,
                                    })
                                  }
                                  autoComplete="off"
                                />
                              </div>

                              <div className="mb-3">
                                <input
                                  type="hidden"
                                  name="prePrice"
                                  value={values.prePrice}
                                />
                              </div>
                              <div className="mb-3">
                               

                                <input
                                  type="hidden"
                                  name="postPrice"
                                  className="form-control"
                                  // style={{ textTransform: "capitalize" }}
                                  value={values.completeSurveyPrice}
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      completeSurveyPrice: e.target.value,
                                    })
                                  }
                                  autoComplete="off"
                                />
                              </div>
                              <div className="mb-3">
                                <label className="form-label">
                                  {" "}
                                  completeSurevyPrice
                                </label>

                                <input
                                  type="text"
                                  name="completeSurveyPrice"
                                  className="form-control"
                                  // style={{ textTransform: "capitalize" }}
                                  value={values.completeSurveyPrice}
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      completeSurveyPrice: e.target.value,
                                    })
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Address
                                </label>

                                <textarea
                                  className="form-control"
                                  placeholder="Address"
                                  name="address"
                                  value={values.address}
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      address: e.target.value,
                                    })
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <input
                                  type="hidden"
                                  name="modifiedBy"
                                  className="form-control"
                                  value={values.modifiedBy}
                                  autoComplete="off"
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      modifiedBy: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">LOGO: </label>
                                {values.tempImageUrl ? (
                                  <img
                                    type="image"
                                    className="form-control"
                                    src={values.tempImageUrl} // Use the temporary image URL here
                                    name="logo"
                                    alt="Selected Logo"
                                  />
                                ) : values.logo ? (
                                  <img
                                    type="image"
                                    className="form-control"
                                    src={staticImagePath + values.logo}
                                    name="logo"
                                    alt="Existing Logo"
                                  />
                                ) : null}
                                <input
                                  type="file"
                                  className="form-control"
                                  name="accountLogo"
                                  onChange={handleImageChange}
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Status
                                </label>
                                <select
                                  name="IsActive"
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      isActive: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                  value={values.isActive}
                                  required
                                >
                                  <option value="true">Active</option>
                                  <option value="false">Inactive</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 text-center">
                            <button
                              className="btn btn-primary waves-effect waves-light btn-rounded px-5"
                              type="submit"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightbar-overlay"></div>
    </div>
  );
};

export default EditForm;
